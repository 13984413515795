import React, { useState } from "react";
import { Generator, Login } from "./style";

import { GenerateQrcode } from "./store";

import { Modal } from "antd";
import { useNavigate } from "react-router-dom";

import "react-phone-number-input/style.css";
function Qrcode() {
  let navigate = useNavigate();
  const [
    { qrCode, number, otp, signres, profile },
    {
      postqrcode,
      setName,
      setPhone,

      setNumber,
      onSubmit,
      setOtp,
      sentVarify,
    },
  ] = GenerateQrcode();
  console.log(profile, "pr");

  localStorage.setItem("userid", profile.chat_account_id);
  const account = profile.chat_account_id;
  console.log(account, "..........aaaaaaaaaaaaaaaa");
  // console.log(typeof value,'...............value')
  // console.log(value,'...............value')
  const generateQrCode = (value) => {
    postqrcode(value);
    setShowdiv(true);
  };

  const [showdiv, setShowdiv] = useState(false);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <Generator>
      <div className="qrcode">
        <div className="wrapper">
          <div className="qhead">
            <h1>Generate Your own QR Code</h1>
          </div>
          <div className="input">
            {/* <div className='pwrqr'>
            <PoweroffOutlined style={{fontSize:'18px'}} onClick={PowerLink} />
            </div> */}

            <div className="section">
              <input
                type="text"
                placeholder="Name"
                onChange={(e) => setName(e.target.value)}
              />
              <input
                type="text"
                placeholder="mobile"
                onChange={(e) => setPhone(e.target.value)}
              />
              {/* {profile.premium_account === false ? ( */}
              <div className="premium">
                <input
                  type="text"
                  placeholder="Address"
                  // onChange={(e) => setaddress(e.target.value)}
                  // readOnly={true}
                  onClick={showModal}
                />
                <input
                  type="text"
                  placeholder="Street"
                  // onChange={(e) => setStreet(e.target.value)}
                  // readOnly={true}
                  onClick={showModal}
                />
                <input
                  type="text"
                  placeholder="City"
                  // onChange={(e) => setCity(e.target.value)}
                  // readOnly={true}
                  onClick={showModal}
                />
                <input
                  type="text"
                  placeholder="State"
                  // onChange={(e) => setState(e.target.value)}
                  // readOnly={true}
                  onClick={showModal}
                />
                <input
                  type="text"
                  placeholder="Country"
                  // onChange={(e) => setCountry(e.target.value)}
                  // readOnly={true}
                  onClick={showModal}
                />
              </div>
              {/* ) : (
                 <div className="premium">
                <input
                    type="text"
                    placeholder="Address"
                    onChange={(e) => setaddress(e.target.value)}
                    // readOnly={true}
                  />
                  <input
                    type="text"
                    placeholder="Street"
                    onChange={(e) => setStreet(e.target.value)}
                    // readOnly={true}
                  />
                  <input
                    type="text"
                    placeholder="City"
                    onChange={(e) => setCity(e.target.value)}
                    // readOnly={true}
                  />
                  <input
                    type="text"
                    placeholder="State"
                    onChange={(e) => setState(e.target.value)}
                    // readOnly={true}
                  />
                  <input
                    type="text"
                    placeholder="Country"
                    onChange={(e) => setCountry(e.target.value)}
                    // readOnly={true}
                  />
                </div>
              )} */}
              <div className="generatebutton">
                <button onClick={() => generateQrCode()}>Generate</button>
              </div>
            </div>
            {showdiv ? (
              <div className="imageqr">
                {qrCode ? (
                  <div className="www">
                    <img src={qrCode.qrcode} alt="" />
                  </div>
                ) : (
                  ""
                )}
                {/* <div className='resolution'>
                <input type='range' min='200' max='800'  onChange={(e) => setSize(e.target.value)}/>
              </div> */}
                <div className="qrdwnld">
                  {qrCode ? (
                    <a href={qrCode.pdf}>
                      <button type="button">Download</button>
                    </a>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>

      <Modal
        title="Sign-Up"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <Login>
          {signres === null ? (
            <div className="signup-page">
              <div className="input">
                <div className="box">
                  <input
                    maxlength="13"
                    type="text"
                    placeholder="Enter your Number"
                    onChange={(e) => setNumber(e.target.value)}
                    value={number}
                  ></input>
                  {/* <PhoneInput defaultCountry="IND" value={value}   onChange={setValue} /> */}
                </div>
              </div>
              <div id="recaptcha-container"></div>
              <div className="button">
                <button type="button" onClick={() => onSubmit()}>
                  Send OTP
                </button>
              </div>
            </div>
          ) : (
            <div className="signup-page">
              <div className="input">
                <div className="box1">
                  <input
                    type="text"
                    placeholder="Enter your OTP"
                    onChange={(e) => setOtp(e.target.value)}
                    value={otp}
                  ></input>
                </div>
              </div>

              <div className="button">
                <button
                  type="button"
                  onClick={() => sentVarify(navigate, setIsModalVisible(false))}
                >
                  Sign in
                </button>
              </div>
            </div>
          )}
        </Login>
      </Modal>
    </Generator>
  );
}

export default Qrcode;
