import styled from "styled-components";
export const Contactqr = styled.div`
  .contactdiv {
    display: flex;
    // background: #f5f5f5;
    justify-content: center;
    align-items: center;
gap:20px;
    height: 600px;
    .contactbox {
      width:460px;
      height:450px;
      background: #f5f5f5;
      box-shadow: -10px -10px 15px rgba(255, 255, 255, 0.5),
        10px 10px 15px rgba(70, 70, 70, 0.12);
      border-radius: 80px;
      border: 3px solid white;
      display: flex;
      flex-direction: column;
      padding: 30px;

      .address {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
        padding: 10px;
        margin-top:10px;
        .icon {
          width: 120px;
          height: 30px;

          img {
            width: 100%;
            height: 100%;
          }
          @media(max-width:546px){
            width:100px;
          }
        }
        .location {
          h1 {
            font-size: 18px;
            text-align: justify;
            padding: 0px;
            margin: 0px;
            font-family: 'DM Sans', sans-serif;
            @media(max-width:546px){
              font-size: 16px;
            }
            @media(max-width:460px){
              font-size:14px;
            }
          }
        }
        @media(max-width:746px){
          margin-top:unset;
        }
      }
      .email {
        display: flex;
        padding: 10px;

        gap: 20px;
        .icon1 {
          width: 30px;
          height: 30px;
          img {
            width: 100%;
            height: 100%;
          }
          @media(max-width:546px){
            width:25px;
            height:25px;
          }
        }
        .mail {
          h1 {
            font-size: 18px;
            text-align: justify;
            padding: 0px;
            margin: 0px;
            font-family: 'DM Sans', sans-serif;
            @media(max-width:546px){
              font-size: 16px;
            }
            @media(max-width:460px){
              font-size:14px;
            }
          }
        }
        @media(max-width:746px){
          padding:unset;
          padding-left:10px;
        }
      }
      .telephone {
        display: flex;
        padding: 10px;

        gap: 20px;
        .icon2 {
          width: 30px;
          height: 30px;
          img {
            width: 100%;
            height: 100%;
          }
          @media(max-width:546px){
            width:25px;
            height:25px;
          }
        }
        .phone {
          h1 {
            font-size: 18px;
            text-align: justify;
            padding: 0px;
            margin: 0px;
            font-family: 'DM Sans', sans-serif;
            @media(max-width:546px){
              font-size: 16px;
            }
            @media(max-width:460px){
              font-size:14px;
            }
          }
        }
        @media(max-width:746px){
          padding:unset;
          padding-left:10px;
        }
      }
      // @media(max-width:980px){
      //   width:350px;
      //   height:350px;
      // }
      // @media(max-width:400px){
      //   width: 280px;
      //   height: 312px;
      //   padding:15px;
      // }
      // @media(max-width:350px){
      //   width: 250px;
      // }
      @media(max-width:746px){
        width:400px;
        height:400px;
      }
      @media(max-width:546px){
        width:360px;
        height:350px;
      }
      @media(max-width:460px){
        width:300px;
      }
      @media(max-width:400px){
        width:260px;
        height:356px;
      }
    }
    .cntctqr{
      display:flex;
      justify-content:center;
      .qrcntct{
        width:150px;
        height:150px;
        img{
          width:100%;
          height:100%;
        }
        @media(max-width:746px){
          width:130px;
          height:130px;
        }
        @media(max-width:546px){
          width:100px;
          height:100px;
        }
        @media(max-width:400px){
          width:80px;
          height:80px;
        }
      }
    }
   
  
  }
`;
