import React, { useState, useEffect } from "react";
import { Adminheader, DIV } from "./style";
import chupqr from "../../../image/chupqr.svg";
import { PoweroffOutlined, MenuOutlined } from "@ant-design/icons";
import { Switch } from "antd";
import { GetAdmin, GetHeader } from "./store";
import { Popconfirm, Popover, Drawer } from "antd";
import { getAuth, signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { GetSiteStatus } from "../../Website/Common/Widget/store";
import { firebase } from "../../../common/firebase";
import { Link } from "react-router-dom";
function Header() {
  const [
    { isLoading, status, menubar },
    { postsitestatus, setTogglebar, setMenubar },
  ] = GetAdmin();
  const [{ statuss }, { getstatus }] = GetSiteStatus();

  const Menubaropn = () => {
    setMenubar(!menubar);
  };
  console.log(menubar, ".....menu");
  const toggler = (value) => {
    setTogglebar(value);
    postsitestatus();
  };

  let navigate = useNavigate();
  const content = (
    <div>
      <small>Sign Out</small>
    </div>
  );
  const content1 = (
    <div>
      <small>site on/off</small>
    </div>
  );
  const signout = () => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        navigate(`/`);
      })
      .catch((error) => {});
  };
  //.................drawer

  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };
  //.........................

  console.log(status, "...status");
  const [token, setToken] = useState("");
  useEffect(() => {
    //import { firebase } from "../../../Common/firebase";
    return firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        user
          .getIdToken(true)
          .then((latestToken) => setToken(latestToken))
          .catch((err) => console.log(err));
      }
    });
  }, []);
  localStorage.setItem("token", token);
  React.useEffect(() => {
    getstatus();
  }, [status, token]);
  console.log(typeof status, ".....sts");

  return (
    <Adminheader>
      <div className="chupqr">
        <div className="qrlogo">
          <MenuOutlined
            className="menu"
            style={{ fontSize: "28px", fontWeight: "bold" }}
            onClick={showDrawer}
          />
          <img src={chupqr} alt="logo" />
        </div>
        <div className="power">
          <Popover content={content1} placement="left">
            <Switch onClick={toggler} />
          </Popover>
          <Popover content={content} placement="right">
            <Popconfirm
              title="Are you sure？"
              placement="bottom"
              okText="Yes"
              cancelText="No"
              onConfirm={() => signout()}
            >
              <PoweroffOutlined
                style={{
                  fontSize: "38px",
                  color: "#57b9d7",
                  fontWeight: "bold",
                }}
              />
            </Popconfirm>
          </Popover>
        </div>
      </div>
      <Drawer placement="left" onClose={onClose} visible={visible}>
        <DIV>
          <div className="drawer">
            <div className="drawersec">
              <div className="drawerhead">
                <h1>Admin</h1>
              </div>
              <div className="drawerlistitems">
                <ul>
                  <Link to="banner" style={{ textDecoration: "none" }}>
                    <li>Banner (Web)</li>
                  </Link>
                  <Link to="ad" style={{ textDecoration: "none" }}>
                    <li>Ad (App)</li>
                  </Link>
                  <Link to="statusimage" style={{ textDecoration: "none" }}>
                    <li>SiteStatus Image</li>
                  </Link>
                  <Link to="paymentlist" style={{ textDecoration: "none" }}>
                    <li>Payment List</li>
                  </Link>
                  <Link to="coupoun" style={{ textDecoration: "none" }}>
                    <li>Coupon premium</li>
                  </Link>
                  <Link to="couponlist" style={{ textDecoration: "none" }}>
                    <li>Coupon List</li>
                  </Link>
                  <Link to="premiumlist" style={{ textDecoration: "none" }}>
                    <li>Premium List</li>
                  </Link>
                  <Link to="versions" style={{ textDecoration: "none" }}>
                    <li>App Version</li>
                  </Link>
                </ul>
              </div>
            </div>
          </div>
        </DIV>
      </Drawer>
    </Adminheader>
  );
}

export default Header;
