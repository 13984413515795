import React, { useEffect } from "react";
import Banner from "../../Banner";
import Contact from "../../Contact";
import Features from "../../Features";
import Footer from "../../Footer";
import Header from "../../header";
import Chupqrhome from "../../Home";
import Qrcode from "../../Qrcode";

function Landing() {
  return (
    <div>
      {/* <Header /> */}
      <Banner/>
      <Chupqrhome />
      <Features />
      <Qrcode />
      <Contact />
      <Footer />
    </div>
  );
}

export default Landing;
