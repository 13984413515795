import { get} from "../../Admin/common/remote/base_api";
import {firebase,auth} from '../../../common/firebase'
export const GetCoupountList = (data) => {
  return get(`/userapp/coupon-premium-accounts/`, data);
};

  export const Logincode = (phonenumber, onSubmit) => {
    // let Capcha = new firebase.auth.RecaptchaVerifier("recaptcha-div");
  
    // return auth.signInWithPhoneNumber(phonenumber, Capcha);
    let recaptcha = new firebase.auth.RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
        callback: function (response) {
      
        },
      }
    );
    let Capcha = recaptcha;
    return auth.signInWithPhoneNumber(phonenumber, Capcha);
  };
  
  export const OTPcode = (otp, signinres) => {
    var credential = firebase.auth.PhoneAuthProvider.credential(
      signinres.verificationId,
      otp
    );
    let sign = firebase
      .auth()
      .signInWithCredential(credential)
      .then((v) => {
        let token =  firebase.auth().currentUser.getIdToken();
        localStorage.setItem('token',token)
      });
  
    return sign;
    };

