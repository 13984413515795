import { GenerateCouponCode } from "../../../../Infrastructure/Admin/GenerateCode";

const actions = {
    setLoading:
      (isLoading) =>
      ({ setState }) => {
        setState({ isLoading });
      },
      setcouponcode:
      (couponcode) =>
      ({ setState }) => {
        setState({couponcode });
      },
      Postgenerate:
      (phone_number) =>
      async ({ dispatch, getState }) => {
        dispatch(actions.setLoading(true));
      const value={phone_number}
        try {
         const res= await GenerateCouponCode(value);
         dispatch(actions.setcouponcode(res));
          dispatch(actions.setLoading(false));
        } catch (err) {
        } finally {
          dispatch(actions.setLoading(false));
          
        }
      },
    }
    export default actions;