import { GetAppVersion, Updateversion } from "../../../../Infrastructure/Admin/Version";

const actions = {
    setLoading:
      (isLoading) =>
      ({ setState }) => {
        setState({ isLoading });
      },
      setversions:
      (versions) =>
      ({ setState }) => {
        setState({ versions });
      },
      setchupqr_app_version:
      (chupqr_app_version) =>
      ({ setState }) => {
        setState({ chupqr_app_version });
      },
      setid:
      (id) =>
      ({ setState }) => {
        setState({ id });
      },
      getappversion:
      () =>
      async ({ setState, dispatch }) => {
        dispatch(actions.setLoading(true));
        try {
          const res = await GetAppVersion();
          setState({ versions: res.data.data });
        } catch (err) {
          dispatch(actions.setLoading(true));
        } finally {
          dispatch(actions.setLoading(false));
        }
      },
      Postupdateversion:
      (setIsModalVisibleversion) =>
      async ({ dispatch, getState }) => {
        dispatch(actions.setLoading(true));
        const { chupqr_app_version,id } = getState();
        var bodyFormdata = new FormData();
        bodyFormdata.append("chupqr_app_version", chupqr_app_version);
        bodyFormdata.append("id", id);
        try {
          await Updateversion(bodyFormdata);
  
          dispatch(actions.setLoading(false));
        } catch (err) {
        } finally {
          dispatch(actions.setLoading(false));
          setIsModalVisibleversion(false);
        }
      },
    }
    export default actions;