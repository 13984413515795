import styled from "styled-components";
export const Member = styled.div`
.frst{
  width:100%;
  height:80px;
  position:fixed;
  top:0px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  display:flex;
  justify-content:space-between;
  align-items:center;
  padding:30px;
  h1{
    font-size:22px;
    display:flex;
    justify-content:center;
    align-items:center;
    
  }
  .chatbtn{
    display:flex;
    width:130px;
    height:40px;
    button{
     width:100%;
     height:100%;
     font-size:18px;
     background: #3bbcdc;
     color:white;
     border: 2px solid white;
     box-shadow: -10px -10px 15px rgba(255, 255, 255, 0.5),
       10px 10px 15px rgba(70, 70, 70, 0.12);
     border-radius: 20px;
     outline: none;

    }
  }
}
.qrcode {
    width: 100%;
    background-color: #f5f5f5;
    display: flex;
    justify-content: center;
    align-items: center;
  
    .wrapper {
      width: 80%;
      flex-wrap: wrap;
      display: flex;
      justify-content: center;
      flex-direction:column;
      align-items:center;
      .qhead{
           padding-top:100px;
           display:flex;
           justify-content:center;
          align-items:center;
          h1{
            font-size:26px;
            font-family: 'DM Sans', sans-serif;
            @media(max-width:740px){
              font-size:24px;
            }
            @media(max-width:400px){
              font-size:20px;
            }
          }

      }
      .input {
        display: flex;

        justify-content: center;
        align-items: center;
        width: 400px;
      //  height: 400px;
        flex-direction: column;
        box-shadow: -10px -10px 15px rgba(255, 255, 255, 0.5),
              10px 10px 15px rgba(70, 70, 70, 0.12);
            border-radius:20px;
        margin-bottom: 30px;
        // margin-top:20px;
        gap: 30px;
        .pwrqr{
          display:flex;
          width:90%;
          justify-content:flex-end;
          font-size:18px;
          margin-top:20px;
        }
        .section {
          display: flex;
          flex-direction: column;
          gap: 20px;
           margin-top:30px;
           margin-bottom:30px;

          input {
            width: 300px;
            height: 6vh;
            background: white;
            box-shadow: -10px -10px 15px rgba(255, 255, 255, 0.5),
              10px 10px 15px rgba(70, 70, 70, 0.12);
            border-radius: 10px;
            border: none;
            padding-left:10px;
            @media(max-width:760px){
              width:250px;
            }
            @media(max-width:450px){
              width:200px;
            }
          }
          .premium{
            display: flex;
            flex-direction: column;
            gap: 20px;

            input {
              width: 300px;
              height: 6vh;
              background: white;
              box-shadow: -10px -10px 15px rgba(255, 255, 255, 0.5),
                10px 10px 15px rgba(70, 70, 70, 0.12);
              border-radius: 10px;
              border: none;
              padding-left:10px;
              @media(max-width:760px){
                width:250px;
              }
              @media(max-width:450px){
                width:200px;
              }
            }

          }
          .generatebutton {
            display:flex;
            justify-content:center;
            align-items:center;
            
            button {
              width: 130px;
              height: 40px;
              letter-spacing:2px;
              font-size: 18px;
              border-radius: 4px;
              border: 2px solid white;
              box-shadow: -10px -10px 15px rgba(255, 255, 255, 0.5),
                10px 10px 15px rgba(70, 70, 70, 0.12);
              border-radius: 20px;
              background: #3bbcdc;
              outline: none;
              color:white;
              @media(max-width:400px){
                font-size:16px;
              }
            }
          }
          @media(max-width:740px){
            margin-top:30px;
          }
        }
        .dimension{
          padding-top: 20px;
          display: flex;
          justify-content: space-around;
          gap: 10px;
        }
        .imageqr {
          margin-bottom:30px;
          gap:20px;
          display:flex;
          flex-direction:column;
         
          .www{
          width: 150px;
          height: 150px;
          border: none !important;
          background: transparent !important
          margin-bottom:50px;
          display:flex;
          flex-direction:column;
           gap:10px;
       
          img {
            width: 100%;
            height: 100%;
            
          }
          h1{
            font-size:18px;
          }
        }
          .qrdwnld{
            display:flex;
            justify-content:center;
            align-items:center;
            button{
              width: 130px;
              height: 40px;
              letter-spacing:2px;
              font-size: 16px;
              border-radius: 4px;
              border: 2px solid white;
              box-shadow: -10px -10px 15px rgba(255, 255, 255, 0.5),
                10px 10px 15px rgba(70, 70, 70, 0.12);
              border-radius: 20px;
              background: #3bbcdc;
              outline: none;
              color: white;
            }
         
          }
       
        }
        @media(max-width:980px){
          width:350px;
          // height:350px;
        }
        @media(max-width:740px){
          width:300px;
          // height:300px;
        }
        @media(max-width:400px){
          width:250px;
          // height:250px;
        }
      }
    }
  }
`;
export const Loginp = styled.div`

.ant-modal-content {
  border-radius: 100px;

}
.upgrade{
   display:flex;
   flex-direction:column;
  justify-content:center;
  align-items:center;
  // gap:20px;
  margin-top:40px;
    h1{
        font-size:16px;
        color:black;
    }
    p{
        font-size:14px;
        color:grey;
    }
    .premiumlogo{
      width:120px;
      height:100px;
      // border-radius:50% 50% 50% 50%;
      // border:1px solid black;
      img{
        width:100%;
        height:100%;
        object-fit:cover;
      }
    }
}
.signup-page {
  // width: 30rem;
  // height: 28rem;
  // box-shadow: 0 0 5px 0 white;
    z-index: 1;
    overflow: hidden;
    margin-bottom: 80px;
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction:column;
    


  .button {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    button {
      background: #3bbcdc;
      padding: 5px;
      width:230px;
     
    border-radius: 50px;
      border: none;
      height:40px;
  
      font-size: 16px;
      font-family: 'Montserrat', sans-serif;
      font-weight: bold;
      color: white;
      text-align: center;
      cursor: pointer;
      &:active {
        background-color: black;
        color: white;
      }
    }
  }
 .codelogin{
         display:flex;
         flex-direction:column;
         margin-top:20px;
         .logindetails{
          display:flex;
          justify-content:center;
          align-items:center;
          flex-direction:column;
          gap:20px;
          h1{
            font-size:14px;
          
          }
          input{
            width: 200px;
            height: 6vh;
            background:white;
            box-shadow: -10px -10px 15px rgba(255, 255, 255, 0.5),
              10px 10px 15px rgba(70, 70, 70, 0.12);
            border-radius: 10px;
            border: none;
            padding-left:10px;
            @media(max-width:760px){
              width:250px;
            }
            @media(max-width:450px){
              width:200px;
            }
          }
          button{
            background: #3bbcdc;
            padding: 5px;
            width:130px;
           
          border-radius: 50px;
            border: none;
            height:40px;
        
            font-size: 16px;
            font-family: 'Montserrat', sans-serif;
            font-weight: bold;
            color: white;
            text-align: center;
            cursor: pointer;
          }
         }
  }
}
 
`;
