import styled from "styled-components";
export const Image=styled.div`
margin-top: 120px;
margin-left: 15vw;
width: 84vw;

display: flex;
justify-content: center;
align-items: center;
margin-bottom:50px;
@media(max-width:1020px){
  margin-left:8vw;
}
.content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction:column;
    width:100%;
    .link{
      width:90%;
      display:flex;
      justify-content:flex-end;
      gap:10px;
      p{
        font-size:18px;
        font-family: "dm_sansregular";
       
        
      }
    }
    .wrapper {
      width: 90%;
      display: flex;
      flex-direction: column;
      @media(max-width:440px){
        width:100%;
      }
      .webheading {
        display: flex;
        margin-top:30px;
        width: 200px;
        h1 {
          font-size: 20px;
          font-family: "montserratregular";
          @media(max-width:350px){
            font-size:18px;
          }
        }
      }
      .boxx {
      .add{
          //  width:100%;
          display:flex;
          justify-content:flex-end;
          // position: relative;
          // overflow: hidden;
          // display: inline-block;
          button{
              width:100px;
              height:30px;
              background-color:#287bff;
              border:none;
              border-radius:8px;
              color:white;
              @media(max-width:350px){
                width:80px;
              }
          }
          // input{
          //   font-size: 100px;
          //   position: absolute;
          //   right: 0;
          //   top: 0;
          //   opacity: 0;
          // }
      }
        table {
       
          border-collapse: collapse;
          width: 100%;
          margin-top: 30px;
         border:none;
          background-color: white;
          box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
         
        td, th {
          border: 1px solid #dddddd;
          text-align: left;
          padding: 8px;
          height:50px;
          font-size:16px;
          font-family: "dm_sansregular";
          @media(max-width:680px){
            font-size:14px;
          }
          @media(max-width:440px){
            font-size:12px;
          }
        }
        button{
          width:80px;
          height:30px;
          
          border:none;
          border-radius:6px;
          display:flex;
          justify-content:center;
          align-items:center;
          @media(max-width:800px){
            width:60px;
          }
          @media(max-width:440px){
            width:40px;
            font-size:14px;
          }
        }
        img{
            width:350px;
            height:300px;
            object-fit:cover;
            @media(max-width:800px){
              width:150px;
              height:150px;
            }
            @media(max-width:490px){
              width:100px;
              height:100px;
            }
            @media(max-width:340px){
              width:80px;
            }
        }
      }
    }
  }
`


