import styled from "styled-components";
export const Generate=styled.div`
  margin-top: 120px;
  margin-left: 15vw;
  width: 84vw;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom:50px;
  @media(max-width:1020px){
    margin-left:8vw;
  }
  .content {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction:column;
      width:100%;
      .link{
        width:90%;
        display:flex;
        justify-content:flex-end;
        gap:10px;
        p{
          font-size:18px;
          font-family: "dm_sansregular";
         
          
        }
      }
      .wrapper {
        width: 90%;
        display: flex;
        flex-direction: column;
        @media(max-width:440px){
          width:100%;
        }
        .webheading {
          display: flex;
          margin-top:30px;
          width: 200px;
          h1 {
            font-size: 20px;
            font-family: "montserratregular";
            @media(max-width:350px){
              font-size:18px;
            }
          }
        }
        .boxx {
        .add{
            //  width:100%;
            display:flex;
            justify-content:flex-end;
            // position: relative;
            // overflow: hidden;
            // display: inline-block;
            button{
                width:100px;
                height:30px;
                background-color:#287bff;
                border:none;
                border-radius:8px;
                color:white;
                @media(max-width:350px){
                  width:80px;
                }
            }
          
        }
          table {
         
            border-collapse: collapse;
            width: 100%;
            margin-top: 30px;
           border:none;
            background-color: white;
            box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
           margin-bottom:30px;
          td, th {
            border: 1px solid #dddddd;
            text-align: left;
            padding: 8px;
            gap:20px;
            height:50px;
            font-size:16px;
            font-family: "dm_sansregular";
            @media(max-width:680px){
              font-size:14px;
            }
            @media(max-width:440px){
              font-size:12px;
            }
          }
          button{
            width:80px;
            height:30px;
            
            border:none;
            border-radius:6px;
            display:flex;
            justify-content:center;
            align-items:center;
            @media(max-width:800px){
              width:60px;
            }
            @media(max-width:440px){
              width:40px;
              font-size:14px;
            }
          }
          img{
              width:350px;
              height:300px;
              @media(max-width:800px){
                width:150px;
                height:150px;
              }
              @media(max-width:490px){
                width:100px;
                height:100px;
              }
              @media(max-width:340px){
                width:80px;
              }
             
          }
        }
      
      }
    }
`;
export const Logincode = styled.div`
.signup-page {
  // width: 30rem;
  // height: 28rem;
  // box-shadow: 0 0 5px 0 white;
  border-radius: 20px;

  z-index: 1;
  
  overflow: hidden;
  margin-bottom: 100px;

    
  .signup {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 140px;

    h1 {
      font-family: "dm_sansregular";
      font-size: 40px;
      font-weight: bold;
      color: white;
  
    }

  }
  
  .input {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    align-items: center;
    border-radius: 8px;

    .box {
      display: flex;
      justify-content: center;
      width: 300px;
      align-items: center;
      label {
        font-family: "dm_sansregular";
        color: white;
        font-size: 18px;
        font-weight: bold;
      }
      input {
        width: 200px;
        height: 40px;
        border-radius: 20px;
        padding-left: 10px;
        border-color: #f5f5f5;
        outline: none;
        font-size: 18px;
        background-color:#f5f5f5;
       letter-spacing:3px;
       color:black;
       opacity:0.6;
     
      
      }
      input::placeholder {
        color: black;
      }
    
    }
    .box1 {
      display: flex;
      justify-content: center;
      width: 300px;
      align-items: center;
      label {
        font-family: "Poppins";
        font-size: 16px;
        font-weight: bold;
      }
      input {
        width:200px;
        height: 40px;
        border-radius: 20px;
        padding-left: 40px;
        border-color: white;
        font-size: 16px;
        outline: none;
        border: 1px solid #3bbddd;
        color:black;
        letter-spacing:3px;
       opacity:0.6;
   
      }
      input::placeholder {
        color: grey;
        font-size:15px;
        letter-spacing:none;
        padding-left:none;
      }
    }
  }
  .button {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    button {
      background:  #3bbcdc;
      padding: 5px;
      width: 200px;
      border: none;
      height: 40px;
      border-radius: 20px;
      font-size: 16px;
      font-family: 'Montserrat', sans-serif;
      font-weight: bold;
      color: white;
      text-align: center;
      cursor: pointer;
      &:active {
        background-color: black;
        color: white;
      }
    }
`;