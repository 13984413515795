import {
  GetPayment,
  Postcodecoupon,
  PostSuccess,
} from "../../../../Infrastructure/Website/Premium";

const actions = {
  setisLoading:
    (isLoading) =>
    ({ setState }) => {
      setState({ isLoading });
    },
  setPayment:
    (payment) =>
    ({ setState }) => {
      setState({ payment });
    },
    setorder:
    (order) =>
    ({ setState }) => {
      setState({order});
    },
  settxStatus:
    (txStatus) =>
    ({ setState }) => {
      setState({ txStatus });
    },
  setorderAmount:
    (orderAmount) =>
    ({ setState }) => {
      setState({ orderAmount });
    },
  setpaymentMode:
    (paymentMode) =>
    ({ setState }) => {
      setState({ paymentMode });
    },
  setorderId:
    (orderId) =>
    ({ setState }) => {
      setState({ orderId });
    },
  settxTime:
    (txTime) =>
    ({ setState }) => {
      setState({ txTime });
    },
  setSignature:
    (signature) =>
    ({ setState }) => {
      setState({ signature });
    },
  settxMsg:
    (txMsg) =>
    ({ setState }) => {
      setState({ txMsg });
    },
  setType:
    (type) =>
    ({ setState }) => {
      setState({ type });
    },
  setreferenceId:
    (referenceId) =>
    ({ setState }) => {
      setState({ referenceId });
    },
    setcf_order_id:
    (cf_order_id) =>
    ({ setState }) => {
      setState({ cf_order_id });
    },
    setorder_status:
    (order_status) =>
    ({ setState }) => {
      setState({order_status });
    },
    setcoupon_code:
    (coupon_code) =>
    ({ setState }) => {
      setState({coupon_code });
    },
   
  getpayment:
    (data) =>
    async ({ setState, dispatch }) => {
      dispatch(actions.setisLoading(true));
      try {
        const res = await GetPayment();
        setState({ payment: res.data.data });
     
        dispatch(actions.setorderId(res.data.data.order_id));
        dispatch(actions.setcf_order_id(res.data.data.cf_order_id));
        dispatch(actions.setorder_status(res.data.data.order_status));
  
      } catch (err) {
    
      } finally {
        dispatch(actions.setisLoading(false));
      }
    },
 postpayment :
    (data) =>
    async ({ dispatch, getState }) => {
      dispatch(actions.setisLoading(true));
      const {
        txStatus,
        orderAmount,
        paymentMode,
        orderId,
        txTime,
        signature,
        txMsg,
        type,
        referenceId,
        order,
        payment,
        
      } = getState();
      var bodyFormdata = new FormData();
      bodyFormdata.append("txStatus",data.transaction.txStatus);
      bodyFormdata.append("referenceId",data.transaction.transactionId);
      bodyFormdata.append("orderId",payment.cf_order_id);
      bodyFormdata.append("txMsg",data.transaction.txMsg);
      try {
        const res = await PostSuccess(bodyFormdata);
        dispatch(actions.setisLoading(false));
      } catch (err) {
      } finally {
        dispatch(actions.setisLoading(false));
      }
    },
    postcodelogin:
    (navigate) =>
    async ({ dispatch, getState }) => {
      dispatch(actions.setisLoading(true));
      const {coupon_code} = getState();
      var bodyFormdata = new FormData();
      bodyFormdata.append("coupon_code", coupon_code);
      
      try {
        const res = await Postcodecoupon(bodyFormdata);
        
        dispatch(actions.setisLoading(false));
        navigate("/premium")
      } catch (err) {
      } finally {
        dispatch(actions.setisLoading(false));
      }
    },
};
export default actions;
