import signInWithEmailAndPassword from "../../../../Infrastructure/Admin/Login";
import {firebase} from '../../../../common/firebase/index';
import { notification } from "antd";
const actions = {
    setisLoading:
      (isLoading) =>
      ({ setState }) => {
        setState({ isLoading });
      },
    setToken:
      (token) =>
      ({ setState }) => {
        setState({ token });
      },
    setUsername:
      (Username) =>
      ({ setState }) => {
        setState({ Username });
      },
    setPassword:
      (Password) =>
      ({ setState }) => {
        setState({ Password });
      },
    Submitlogins:
      (navigate) =>
      async ({ setState, dispatch, getState }) => {
        const { Username, Password } = getState();
        dispatch(actions.setisLoading(true));
        try {
          await signInWithEmailAndPassword(Username, Password);
          const user = firebase.auth().currentUser;
          const mytoken = user && (await user.getIdToken());
          localStorage.setItem("token", mytoken);
          notification["success"]({
            message: "success",
            description: "Admin Logged in successfully",
          });
  
          navigate(`/home/admin/page/banner`);
        } catch (err) {
          notification["warning"]({
            message: "warning",
            description: "Username or Password incorrect",
          });
        } finally {
        }
      },
  };
  export default actions;