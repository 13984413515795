import React, { useEffect } from "react";
import { GetCoupounCode } from "../CoupounCode/store";
import { CouponcodeGenerate } from "./store";
import { Code } from "./style";
function Generatecode() {
  const [{ couponcode }, {Postgenerate }] =
    CouponcodeGenerate();
  const [{ phone_number }, { getcoupounlist }] = GetCoupounCode();

  useEffect(() => {
    getcoupounlist();
  }, []);
  console.log(phone_number, "........cd");

  const handleClick = (e,phone_number) => {
    Postgenerate(phone_number);
  };

  return (
    <Code>
      <div className="borderdiv">
        <div className="generatebutton">
          <button
            onClick={(e) => {
              handleClick(e, phone_number);
            }}
          >
            Generate
          </button>
        </div>
        {couponcode ? (
          <div classname="codebox">
            <h1>{couponcode.data.coupon_code}</h1>
          </div>
        ) : (
          ""
        )}
      </div>
    </Code>
  );
}

export default Generatecode;
