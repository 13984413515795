import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import "antd/dist/antd.css";
import "aos/dist/aos.css";

import Landing from "./Presentation/Website/Common/Landingpage";
import Loader from "./Presentation/Website/Common/Widget";
import Privacy from "./Presentation/Website/PrivacyPolicy";
import Site from "./Presentation/Website/Sitestatus";
import { GetSiteStatus } from "./Presentation/Website/Common/Widget/store";
import React from "react";
import Loginpage from "./Presentation/Admin/Login";
import Page from "./Presentation/Admin/Page";

import WebBanner from "./Presentation/Admin/Banner";
import Ad from "./Presentation/Admin/Ad";
import Statusimage from "./Presentation/Admin/SitestatusImage";
import Payment from "./Presentation/Admin/PaymentList";
import Premium from "./Presentation/Website/premium";
import Chat from "./Presentation/Website/Chat";

import Messages from "./Presentation/Website/Chat/Message";
import CoupounCode from "./Presentation/Admin/CoupounCode";

import Generatecode from "./Presentation/Admin/GenerateCode";
import Couponlist from "./Presentation/Admin/couponList";
import Chatsection from "./Presentation/Website/Chat/Chatsection";
import PremiumList from "./Presentation/Admin/PremiumList";
import Appversion from "./Presentation/Admin/Version";
import Chatroom from "./Presentation/Website/ChatRoom";

import { ChatRoomDetails } from "./Presentation/Website/ChatRoom/store";
import ChatPart from "./Presentation/Website/ChatSection";
import PrivateChat from "./Presentation/Website/Privatechat";

function App() {
  const [{ statuss }, { getstatus }] = GetSiteStatus();

  React.useEffect(() => {
    getstatus();
  }, []);
  console.log(typeof statuss, ".....sts");

  return (
    <BrowserRouter>
      <Routes>
        {/* <Route exact path="/" element={<Loader />} /> */}
        <Route
          exact
          path="/"
          element={statuss === true ? <Site /> : <Loader />}
        />
        <Route
          exact
          path="/home"
          element={statuss === true ? <Site /> : <Landing />}
        />
        {/* <Route exact path="/home" element={<Landing />} /> */}
        <Route exact path="/premium" element={<Premium />} />

        {/* <Route exact path="/premium/chat" element={<Chat />}>
          <Route exact path="/premium/chat" element={<Chatsection />} />
          <Route exact path="msg/:id" element={<Messages />} />
        </Route> */}

        {/* <Route exact path="/premium/chat/chatroom" element={<GlobalChat />} >
        <Route exact path="/premium/chat/chatroom" element={<GlobalSection />} />
        <Route exact path='message/:id' element={<GlobalMessage />} />
        </Route> */}
        {/* <Route exact path='/premium/chat/chatroom' element={<Chatroom/>} >
        <Route exact path='message/:id' element={<Chatroom/>} />
        </Route> */}
        {/* <Route exact path='/premium/chat' element={<PrivateChat/>}/>
        <Route exact path='/premium/chatroom' element={<ChatPart/>} >
        <Route exact path='message/:id' element={<ChatPart />} />
        </Route> */}
        <Route exact path="/privacypolicy" element={<Privacy />} />
        <Route exact path="/home/admin" element={<Loginpage />} />
        <Route exact path="/home/admin/page" element={<Page />}>
          <Route exact path="banner" element={<WebBanner />} />
          <Route exact path="ad" element={<Ad />} />
          <Route exact path="statusimage" element={<Statusimage />} />
          <Route exact path="paymentlist" element={<Payment />} />
          <Route exact path="coupoun" element={<CoupounCode />} />
          <Route exact path="generatecode" element={<Generatecode />} />
          <Route exact path="couponlist" element={<Couponlist />} />
          <Route exact path="premiumlist" element={<PremiumList />} />
          <Route exact path="versions" element={<Appversion />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
