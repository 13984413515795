const initialState = {
    isLoading: false,
    payment:'',
    order:'',
    txStatus:'',
    orderAmount:'',
    paymentMode:'',
    orderId:'',
    txTime:'',
    signature:'',
    txMsg:'',
    type:'',
    referenceId:'',
    cf_order_id:'',
    order_status:'',
    coupon_code:{},
    }
    export default initialState;