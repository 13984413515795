import {
  CreateBanner,
  delBanner,
  GetBanner,
  UpdateBanner,
} from "../../../../Infrastructure/Admin/Banner";
import { notification } from "antd";

const actions = {
  setLoading:
    (isLoading) =>
    ({ setState }) => {
      setState({ isLoading });
    },
  setbanner:
    (banner) =>
    ({ setState }) => {
      setState({ banner });
    },
  setImage:
    (ad_image) =>
    ({ setState }) => {
      setState({ ad_image });
    },
    setImageupdate:
    (ad_images) =>
    ({ setState }) => {
      setState({ ad_images });
    },
  getbanner:
    () =>
    async ({ setState, dispatch }) => {
      dispatch(actions.setLoading(true));
      try {
        const res = await GetBanner();
        setState({ banner: res.data.data });
      } catch (err) {
        dispatch(actions.setLoading(true));
      } finally {
        dispatch(actions.setLoading(false));
      }
    },
  deletebanner:
    (id) =>
    async ({ dispatch, getState }) => {
      try {
        dispatch(actions.setLoading(true));
        await delBanner(id);
        notification["success"]({
          message: "successs",
          description: "Banner deleted Succesfully",
        });
        // dispatch(actions.getbanner());
      } catch (err) {
      } finally {
        dispatch(actions.setLoading(false));
      }
    },
  PostCreatebanner:
    (setIsModalVisible) =>
    async ({ dispatch, getState }) => {
      dispatch(actions.setLoading(true));
      const { ad_image } = getState();
      var bodyFormdata = new FormData();
      bodyFormdata.append("ad_image", ad_image);
      try {
        await CreateBanner(bodyFormdata);

        dispatch(actions.setLoading(false));
      } catch (err) {
      } finally {
        dispatch(actions.setLoading(false));
        setIsModalVisible(false);
      }
    },
    PostUpdatebanner:
    (id,setIsModalopn) =>
    async ({ dispatch, getState }) => {
      dispatch(actions.setLoading(true));
      const { ad_images } = getState();
      var bodyFormdata = new FormData();
      bodyFormdata.append("ad_image", ad_images);
      try {
        await UpdateBanner(id,bodyFormdata);

        dispatch(actions.setLoading(false));
      } catch (err) {
      } finally {
        dispatch(actions.setLoading(false));
        setIsModalopn(false);
      }
    },
};

export default actions;
