import { get,post,del } from "../../Admin/common/remote/base_api";
export const GetBanner = (data) => {
  return get(`/adminapp/list-banner/`, data);
};

export const CreateBanner = (data) => {
  return post(`/adminapp/create-banner/`, data);
};
export const UpdateBanner = (id,data) => {
    return post(`/adminapp/update-banner/${id}/`, data);
  };

export const delBanner = (id) => {
    return del(`/adminapp/delete-banner/${id}`);
  };