import React from "react";
import styled from "styled-components";
import { GetSiteStatus } from "../Common/Widget/store";

function Site() {
  const [{image }, { getstatus}] = GetSiteStatus();

  React.useEffect(() => {
    getstatus();
  }, []);
  return (
    <Gif>
      <img src={image} />
    </Gif>
  );
}

export default Site;

export const Gif = styled.div`
  img {
    width: 100%;
    height: 100vh;
  }
`;
