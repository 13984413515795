import {post,get,del } from "../../Admin/common/remote/base_api";
export const GetAd = (data) => {
  return get(`/userapp/ads/`, data);
};
export const CreateAd = (data) => {
  return post(`/adminapp/create-ads/`, data);
};
export const UpdateAd = (id,data) => {
    return post(`/adminapp/update-ads/${id}/`, data);
  };
  export const delAd = (id) => {
    return del(`/adminapp/delete-ads/${id}`);
  };