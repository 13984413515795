import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
const firebaseConfig = {

    apiKey: "AIzaSyDbW-wZNrrIJ7AGhIoeb7bGzaANmlvhIwM",
  
    authDomain: "chupqr-quadauq.firebaseapp.com",
  
    projectId: "chupqr-quadauq",
  
    storageBucket: "chupqr-quadauq.appspot.com",
  
    messagingSenderId: "578905006893",
  
    appId: "1:578905006893:web:7f025a62609cfafc0b29a4",
  
    measurementId: "G-LLV3YFH99P"
  
  };
  firebase.initializeApp(firebaseConfig);
  var auth = firebase.auth();
  export { auth, firebase };