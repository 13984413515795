import React,{useState,useEffect} from "react";
import { GetCoupounlist } from "./store";
import { firebase } from "../../../common/firebase/index";

import { List } from "./style";
function Couponlist() {
const[{isLoading,choice,response,all},{Postcouponlist,setChoice,setresponse,setall}]=GetCoupounlist()
const handleClick = (e) => {
  setChoice(e.target.value);
  Postcouponlist();
};
const [token, setToken] = useState("");
useEffect(() => {

  return firebase.auth().onAuthStateChanged((user) => {
    if (user) {
      user
        .getIdToken(true)
        .then((latestToken) => setToken(latestToken))
        .catch((err) => console.log(err));
    }
   
  });
}, []);
localStorage.setItem("token", token);
console.log(response,'............res')
  return (
    <List>
      <div className="content">
        <div className="wrapper">
          <div className="webheading">
            <h1>Coupon List</h1>
          </div>
          <div className="boxx">
            <div className='add'>
                 
                    <select value={choice} onChange={handleClick} >
                      <option value="all" defaultValue  >
                        All
                      </option>
                      <option value="all"  >
                        All
                      </option>
                      <option value="coupon-not-used">coupon-not-used</option>
                      <option value="coupon-used">coupon-used</option>
                    </select>
          
            </div>
            <table>
              <thead>
                <tr className="row">
                  <th>Sl.No</th>
                  <th>Id</th>
                  <th>coupon used</th>
                  <th>coupon </th>
                  <th>Phone Number</th>

                 
                </tr>
              </thead>

              <tbody>
           {response ?.map((res,index)=>(
                 <tr className="rowbody">
                <td>{index+1}</td>
                <td>{res.id}</td>
                <td>{res.coupon_used===true ? "Yes" : "No"}</td>
                <td>{res.coupon}</td>
                <td>{res.phone_number}</td>
                </tr>
          ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </List>
  );
}

export default Couponlist;
