import styled from "styled-components";
export const Sidebarpart = styled.div`
  .sidebar {
    width: 15vw;
    height: 90vh;

    background:#e5e5e5;;

    display: flex;
    justify-content: center;
    margin-top:100px;
    position: fixed;
    .sidebarsec{
      height:550px;
      overflow-y: scroll;
        .sidehead{
            display: flex;
            justify-content: center;
            align-items: center;
           margin-top:20px;
            h1 {
              font-size: 20px;
              color: black;
              font-family: "Poppins";
              opacity:0.4;
              letter-spacing: 3px;
            }
        }
        .listitems {
            // height: 630px;
            // overflow-y: scroll;
    
            ul {
              list-style: none;
              margin: 0px;
              padding: 0px;
              display: flex;
              gap: 30px;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              padding-top: 10px;
              padding-bottom: 20px;
              li {
                font-size: 16px;
                width: 180px;
                height: 50px;
                padding: 14px;
                background-color:white;
                box-shadow: -10px -10px 15px rgba(255,255,255,0.5), 10px 10px 15px rgba(70,70,70,0.12);
                border-radius:20px;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: start;
                color:black;
                cursor: pointer;
               
                span {
                }
                &:hover {
                  background-color:#e5e5e5;
                
                 
                }
              @media(max-width:1250px){
                width:160px;
              }
              @media(max-width:1180px){
                font-size:14px;
                width:140px;
              }
              }
            }
          }
    }
    @media(max-width:1020px){
      display:none;
     
    }
  }
`;
