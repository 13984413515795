import { GetCoupountList, ListCoupons, Logincode, OTPcode } from "../../../../Infrastructure/Admin/CoupounCode";
import { notification } from "antd";
import { firebase } from "../../../../common/firebase/index";
const actions = {
    setLoading:
      (isLoading) =>
      ({ setState }) => {
        setState({ isLoading });
      },
      setCoupoun:
      ( coupoun) =>
      ({ setState }) => {
        setState({  coupoun });
      },
      setChoice:
      (choice) =>
      ({ setState }) => {
        setState({choice});
      },
      setsignres:
      (signres) =>
      ({ setState }) => {
        setState({ signres });
      },
    settoken:
      (token) =>
      ({ setState }) => {
        setState({ token });
      },
      setphoneNumber:
      (phone_number) =>
      ({ setState }) => {
        setState({phone_number});
      },
      setotp:
      (otp) =>
      ({ setState }) => {
        setState({otp});
      },
      setdefaultnum:
      (defaultnum) =>
      ({ setState }) => {
        setState({defaultnum});
      },
      getcoupounlist:
      () =>
      async ({ setState, dispatch }) => {
        dispatch(actions.setLoading(true));
        try {
          const res = await GetCoupountList();
          setState({coupoun: res.data.data });
        } catch (err) {
          dispatch(actions.setLoading(true));
        } finally {
          dispatch(actions.setLoading(false));
        }
      },
      // Postcouponlist:
      // () =>
      // async ({ dispatch, getState }) => {
      //   dispatch(actions.setLoading(true));
      //   const { choice } = getState();
      //   var bodyFormdata = new FormData();
      //   bodyFormdata.append("choice",choice);
      //   try {
      //    const res= await ListCoupons(bodyFormdata);
          
      //     dispatch(actions.setLoading(false));
      //   } catch (err) {
      //   } finally {
      //     dispatch(actions.setLoading(false));
          
      //   }
      // },
      onSubmitcode:
      () =>
      async ({ setState, dispatch, getState }) => {
        dispatch(actions.setLoading(true));
  
        try {
          const { phone_number } = getState();
          let temporary = "+91";
          let main = temporary + phone_number;
          let int = parseInt(main);
  
          if (phone_number.length < 10) {
            notification["warning"]({
              message: "warning",
              description: "Mobile number is not valid (+91 required)",
            });
            return false;
          } else {
            const signres = await Logincode(phone_number);
            dispatch(actions.setsignres(signres));
           
          }
        } catch (err) {
        } finally {
          dispatch(actions.setLoading(false));
        }
      },
      sentVarifycode:
      (navigate) =>
      async ({ setState, dispatch, getState }) => {
        dispatch(actions.setLoading(true));
        try {
          const { otp, signres, profile } = getState();
          if (otp === "") {
            notification["warning"]({
              message: "warning",
              description: "OTP feild is empty ",
            });
            return false;
          } else {
            await OTPcode(otp, signres);
            const user = firebase.auth().currentUser;
            const mytoken = user && (await user.getIdToken());
  
            localStorage.setItem("token", mytoken);
          }
          notification["success"]({
            message: "success",
            description: "Login Successfully",
          });
  
          navigate("/home/admin/page/generatecode");
        } catch (err) {
          notification["warning"]({
            message: "warning",
            description: "Invalid OTP Verification failed",
          });
        } finally {
          dispatch(actions.setLoading(false));
        
        }
      },
    }
    export default actions;