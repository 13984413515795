import React,{useState,useEffect} from "react";
import { Image } from "./style";
import { EditOutlined } from "@ant-design/icons";
import { GetStatusimage } from "./store";
import {  Modal } from "antd";
import { GetSiteStatus } from "../../Website/Common/Widget/store";
import { firebase } from "../../../common/firebase";
function Statusimage() {
  const [{ isLoading, images }, { PostStatusimage,setImages }] = GetStatusimage();
  const [isModalVisible, setIsModalVisible] = useState(false);
  console.log(images,'...........statusimage')
  const showModalstatus = () => {
    setIsModalVisible(true);
  };

  const handleOkstatus = () => {
    PostStatusimage( setIsModalVisible);
  
  };

  const handleCancelstatus = () => {
    setIsModalVisible(false);
  };  const [{image }, { getstatus}] = GetSiteStatus();
  console.log(image,'...........img')
  const [token, setToken] = useState("");
  useEffect(() => {
    //import { firebase } from "../../../Common/firebase";
    return firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        user
          .getIdToken(true)
          .then((latestToken) => setToken(latestToken))
          .catch((err) => console.log(err));
      }
    });
  }, []);
  localStorage.setItem("token", token);
    React.useEffect(() => {
      getstatus();
    }, []);
  return (
    <Image>
      <div className="content">
       
        <div className="wrapper">
          <div className="webheading">
            <h1>Status Image</h1>
          </div>
          <div className="boxx">
            <table>
              <thead>
                <tr className="row">
                  <th>Sl.No</th>
                  <th>File</th>
                  <th> Add</th>
                </tr>
              </thead>

              <tbody>
                <tr className="rowbody">
                  <td>1</td>
                  <td><img src={image} alt='img'/></td>

                  <td>
                    <button onClick={ showModalstatus }>
                      <EditOutlined style={{ fontSize: 18 }} />
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Modal title="Add Image" visible={isModalVisible} onOk={handleOkstatus} onCancel={handleCancelstatus}>
      <input type="file" name="myfile" onChange={(e) => setImages(e.target.files[0])} />
      </Modal>
    </Image>
  );
}

export default Statusimage;
