import React from "react";
import { Contactqr } from "./style";
import location from "../../../image/location.png";
import email from "../../../image/mail.png";
import phone from "../../../image/telephone.png";
import cntctqr from '../../../image/cntctqr.png'
function Contact() {
  return (
    <Contactqr id='contact'>
      <div className="contactdiv">
        <div className="contactbox">
          <div className='cntctqr'>
          <div className='qrcntct'>
            <img src={cntctqr} alt=''/>
          </div>
          </div>
          
          <div className="address">
            <div className="icon">
              <img src={location} alt="img" />
            </div>
            <div className="location">
              <h1>
                 39/2701, 1st Floor Pallickel Building, (75) South janatha
                road, Palarivattom, Ernakulam, Kochi, 682025, Kerala, IN
              </h1>
            </div>
          </div>
          <div className="email">
            <div className="icon1">
              <img src={email} alt="img" />
            </div>
            <div className="mail">
              <h1>chupqr@gmail.com</h1>
            </div>
          </div>
          <div className="telephone">
            <div className="icon2">
              <img src={phone} alt=''/>
            </div>
            <div className="phone">
              <h1> 9048444416<br/> 0484-2994416</h1>
            </div>
          </div>
        </div>
       
      </div>
    </Contactqr>
  );
}

export default Contact;
