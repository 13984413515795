import styled from "styled-components";
export const Heading=styled.div`
display:flex;
.chupqr{
    width:100%;
    height:80px;
    display:flex;
    justify-content:center;
    align-items:center;
    // background:rgba(0,0,0,.1);
    background:#f5f5f5;
    position:fixed;
    z-index:100;
    gap:10px;
    .qrlogo{
        display:flex;
        width:180px;
        height:60px;
        // background-color:red;
        img{
            width:100%;
            height:100%;
            object-fit:cover;
        }
    }
    .qrhead{
        h1{
            font-size:30px;
            color:#30a5c1;
            font-weight:bold;
            margin:0px;
            padding:0px;
            font-family: 'Poppins', sans-serif;
        }
    }
}
`