import React from "react";
import { Link } from "react-router-dom";
import { GetAdmin } from "../Header/store";
import { Sidebarpart } from "./style";
function Sidebar() {
  const [{ menubar }, { setMenubar }] = GetAdmin();
  const [Width, setWidth] = React.useState(0);

  React.useEffect(() => {
    function handleResize() {
      setWidth({
        width: window.innerWidth,
      });
    }

    window.addEventListener("resize", handleResize);

    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  console.log(Width, "...width");

  return (
    <Sidebarpart>
      <div className="sidebar">
        <div className="sidebarsec">
          <div className="sidehead">
            <h1>Admin</h1>
          </div>
          <div className="listitems">
            <ul>
              <Link to="banner" style={{ textDecoration: "none" }}>
                <li>Banner (Web)</li>
              </Link>
              <Link to="ad" style={{ textDecoration: "none" }}>
                <li>Ad (App)</li>
              </Link>
              <Link to="statusimage" style={{ textDecoration: "none" }}>
                <li>SiteStatus Image</li>
              </Link>
              <Link to="paymentlist" style={{ textDecoration: "none" }}>
                <li>Payment List</li>
              </Link>
              <Link to="coupoun" style={{ textDecoration: "none" }}>
                <li>Coupon premium</li>
              </Link>
              <Link to="couponlist" style={{ textDecoration: "none" }}>
                <li>Coupon List</li>
              </Link>
              <Link to="premiumlist" style={{ textDecoration: "none" }}>
                <li>Premium List</li>
              </Link>
              <Link to="versions" style={{ textDecoration: "none" }}>
                <li>App Version</li>
              </Link>
            </ul>
          </div>
        </div>
      </div>
    </Sidebarpart>
  );
}

export default Sidebar;
