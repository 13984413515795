import { siteStatuss } from "../../../../../Infrastructure/Website/Common/Widget";


const actions = {
    setLoading:
      (isLoading) =>
      ({ setState }) => {
        setState({ isLoading });
      },
      setStatus:
      (statuss) =>
      ({ setState }) => {
        setState({ statuss });
      },
      setImage:
      (image) =>
      ({ setState }) => {
        setState({ image });
      },
      getstatus:
      () =>
      async ({ setState,dispatch }) => {
        dispatch(actions.setLoading(true));
        try {
          const res = await  siteStatuss();
          setState({statuss : res.data.data.status})
          setState({image : res.data.data.image})
        } catch (err) {
          dispatch(actions.setLoading(true));
        } finally {
          dispatch(actions.setLoading(false));
        }
      },
  };
  
  export default actions;