import React, { useEffect, useState } from "react";
import { GetVersion } from "./store";
import { Version } from "./style";
import { EditOutlined } from "@ant-design/icons";
import { Modal } from "antd";
function Appversion() {
  const [
    { isLoading, versions },
    { getappversion, Postupdateversion},
  ] = GetVersion();

  const [isModalvisibleversion, setIsModalVisibleversion] = useState(false);
  const showModal = () => {
    setIsModalVisibleversion(true);
  };

  const handleOk = () => {
    Postupdateversion(setIsModalVisibleversion);
   
  };

  const handleCancel = () => {
    setIsModalVisibleversion(false);
  };

  useEffect(() => {
    getappversion();
  }, []);
  console.log(versions, "......................vs");
  return (
    <Version>
      <div className="content">
        <div className="wrapper">
          <div className="webheading">
            <h1>App Version</h1>
          </div>
          <div className="boxx">
            <table>
              <thead>
                <tr className="row">
                  <th>id</th>
                  <th>Version</th>
                  <th>Update</th>
                </tr>
              </thead>

              <tbody>
                <tr className="rowbody">
                  <td>{versions.id}</td>
                  <td>{versions.chupqr_app_version}</td>
                  <td>
                    <button onClick={showModal}>
                      <EditOutlined style={{ fontSize: 18 }} />
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Modal
        title="Update App Version"
        visible={isModalvisibleversion}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div className='appversion' style={{display:'flex',gap:'20px'}}>
          <input type="type" placeholder="enter id" />
          <input type="type" placeholder="enter version" />
        </div>
      </Modal>
    </Version>
  );
}

export default Appversion;
