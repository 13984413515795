import React, { useEffect, useState } from "react";
import { Loading } from "./style";
import { useNavigate } from "react-router";
import goodmorning from "../../../../image/goodmorning.mpga";
import goodafternoon from "../../../../image/goodafternoon.mpga";
import goodevening from "../../../../image/good evening.mp3";
import { GetSiteStatus } from "./store";


function Loader() {
  let time = new Date().getHours();
  let greet = "";
  let msg='';
  if ((time >= 0) & (time < 12)) {
    greet = "Hey,Good Morning !";
    msg= <iframe width='0' height="0" src={goodmorning} frameBorder='0' allowFullScreen/>;
  } else if ((time >= 12) & (time <= 16)) {
    greet = "Hey,Good Afternoon !";
    msg= <iframe width='0' height="0" src={ goodafternoon } frameBorder='0' allowFullScreen/>;
  } else {
    greet = "Hey,Good Evening !";
    msg= <iframe width='0' height="0" src={goodevening } frameBorder='0' allowFullScreen/>;
  }
  const [loader, setLoader] = useState(false);
  const [{ isLoading, statuss,image }, { getstatus, setLoading }] = GetSiteStatus();
  console.log(statuss,'....sts')
  let navigate = useNavigate();
  const loading = () => {
    const timer = setTimeout(() => {
      navigate("/home");
    }, 3000);
  };
  useEffect(() => {
    loading();
  }, []);
  return (
    <Loading
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <div className="body">
        <h2>
          <span>
            {greet}
            {msg}{" "}
          </span>
        </h2>
      </div>
    </Loading>
  );
}

export default Loader;
