import { createStore, createHook, defaults } from "react-sweet-state";
import initialState from "./initialState";
import actions from "./actions";

defaults.devtools = true;
const Qrcode = createStore({
  name: "qrcodepost",
  initialState,
  actions,
});

export const GenerateQrcode = createHook(Qrcode, {
  selector: (state) => state,
});
