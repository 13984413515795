import React, { useState, useEffect } from "react";
import { GenerateQrcode } from "../Qrcode/store";
import { Member, Loginp } from "./style";
import { PoweroffOutlined } from "@ant-design/icons";
import { Modal, Popconfirm } from "antd";
import { useNavigate } from "react-router-dom";
import { firebase } from "../../../common/firebase/index";
import { notification } from "antd";

import premiumlogo from "../../../image/premium.png";

import { MakePayment } from "./store";

function Premium() {
  let navigate = useNavigate();

  const [
    {
      isLoading,

      qrCode,

      profile,
      token,
    },
    {
      postqrcode,
      setName,
      setPhone,
      setaddress,
      setStreet,
      setCity,
      setState,
      setCountry,

      getprofile,
      settoken,
    },
  ] = GenerateQrcode();

  const [
    { payment, coupon_code },
    { getpayment, postpayment, setcoupon_code, postcodelogin },
  ] = MakePayment();

  const account = profile.chat_account_id;
  localStorage.setItem("iddd", account);
  const accntids = localStorage.getItem("iddd");
  console.log(accntids, "................aaaaaaaaa");

  console.log(payment, "...................payment");
  console.log(coupon_code, "..................codelogin");
  const generateQrCode = (value) => {
    postqrcode(value);
    setShowdiv(true);
    // window.location.reload();
  };

  const [showdiv, setShowdiv] = useState(false);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
    getpayment();
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const PowerLink = () => {
    firebase.auth().signOut();

    window.location.replace("/");
    notification["success"]({
      message: "success",
      description: "User Signout Successfully",
    });
  };

  const codelogin = () => {
    postcodelogin();
  };

  //cashfree integration...................................

  let order_token = payment.order_token;
  console.log(order_token, "..........ordrtkn");
  const cashfree = new window.Cashfree();
  const paymentDom = document.getElementById("payment-form");
  console.log(paymentDom, ".........pay");
  const success = function (data) {
    if (payment.order_status === "ACTIVE") {
      postpayment(data);
    } else {
      alert("Order is ACTIVE");
    }

    console.log(data.order, "........success");
    console.log(data.transaction, "........txnsuccess");
  };

  console.log(payment.order_status, ".........ordrsts");
  let failure = function (data) {
    alert("payment failed");
  };
  const makepayment = (data) => {
    console.log(makepayment, "funtion called first time");
    console.log(data, "........datamakepayment ");
    const dropConfig = {
      components: ["order-details", "card", "netbanking", "app", "upi"],
      orderToken: order_token,

      onSuccess: success,
      onFailure: failure,
      style: {
        backgroundColor: "#ffffff",
        color: "#11385b",
        fontFamily: "Lato",
        fontSize: "14px",
        errorColor: "#ff0000",
        theme: "light", //(or dark)
      },
    };

    if (order_token === "") {
      console.log(data, "..........data");
      // $.ajax({
      //     url: "fetchtoken.php",
      //     success: function(result) {
      //         order_token = result["order_token"];
      //         cashfree.initialiseDropin(paymentDom, dropConfig);
      //     },
      // });
    } else {
      cashfree.initialiseDropin(paymentDom, dropConfig);
    }
  };

  //........................................................
  const handleClick = () => {
    navigate("/premium/chat");
  };

  useEffect(() => {
    return firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        user
          .getIdToken(true)
          .then((latestToken) => settoken(latestToken))
          .catch((err) => console.log(err));
      }
      if (token === "") {
      } else {
        getprofile();
      }
    });
  }, [token]);

  console.log(token, "...........token");
  return (
    <Member>
      <div className="frst">
        <h1>Welcome {profile.name}</h1>
        {/* <div className="chatbtn">
          <button onClick={handleClick}>Chat</button>
        </div> */}
      </div>
      <div className="qrcode">
        <div className="wrapper">
          <div className="qhead">
            <h1>Generate Your own QR Code</h1>
          </div>
          <div className="input">
            <div className="pwrqr">
              <Popconfirm
                placement="bottom"
                title="Are you sure"
                onConfirm={() => PowerLink()}
                okText="Yes"
                cancelText="Cancel"
              >
                <PoweroffOutlined
                  style={{ fontSize: "18px" }}
                  onClick={PowerLink}
                />
              </Popconfirm>
            </div>

            <div className="section">
              <input
                type="text"
                placeholder="Name"
                onChange={(e) => setName(e.target.value)}
              />
              <input
                type="text"
                placeholder="mobile"
                onChange={(e) => setPhone(e.target.value)}
              />
              {profile.premium_account === false ? (
                <div className="premium">
                  <input
                    type="text"
                    placeholder="Address"
                    onClick={() => showModal()}
                  />
                  <input
                    type="text"
                    placeholder="Street"
                    onClick={() => showModal()}
                  />
                  <input
                    type="text"
                    placeholder="City"
                    onClick={() => showModal()}
                  />
                  <input
                    type="text"
                    placeholder="State"
                    onClick={() => showModal()}
                  />
                  <input
                    type="text"
                    placeholder="Country"
                    onClick={() => showModal()}
                  />
                </div>
              ) : (
                <div className="premium">
                  <input
                    type="text"
                    placeholder="Address"
                    onChange={(e) => setaddress(e.target.value)}
                  />
                  <input
                    type="text"
                    placeholder="Street"
                    onChange={(e) => setStreet(e.target.value)}
                  />
                  <input
                    type="text"
                    placeholder="City"
                    onChange={(e) => setCity(e.target.value)}
                  />
                  <input
                    type="text"
                    placeholder="State"
                    onChange={(e) => setState(e.target.value)}
                  />
                  <input
                    type="text"
                    placeholder="Country"
                    onChange={(e) => setCountry(e.target.value)}
                  />
                </div>
              )}
              <div className="generatebutton">
                <button onClick={() => generateQrCode()}>Generate</button>
              </div>
            </div>
            {showdiv ? (
              <div className="imageqr">
                {qrCode ? (
                  <div className="www">
                    <img src={qrCode.qrcode} />
                  </div>
                ) : (
                  ""
                )}

                <div className="qrdwnld">
                  {qrCode ? (
                    <a href={qrCode.pdf}>
                      <button type="button">Download</button>
                    </a>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <Modal
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        header={null}
        bodyStyle={{
          borderRadius: "250px",
        }}
      >
        <Loginp>
          <div className="upgrade">
            <div className="premiumlogo">
              <img src={premiumlogo} />
            </div>
            <h1>Upgrade Premium</h1>
            <p>Do more with premium</p>
          </div>

          <div className="signup-page">
            <div id="payment-form"></div>
            <div className="button">
              {isLoading === true ? (
                <div>Loading</div>
              ) : (
                <button type="button" onClick={() => makepayment()}>
                  Go premium for ₹111
                </button>
              )}
            </div>
            <div className="codelogin">
              <div className="logindetails">
                <h1>or</h1>
                <input
                  type="text"
                  placeholder="enter your couponcode"
                  onChange={(e) => setcoupon_code(e.target.value)}
                />
                <button onClick={codelogin}>submit</button>
              </div>
            </div>
          </div>
        </Loginp>
      </Modal>
    </Member>
  );
}

export default Premium;
