const initialState = {
    isLoading: "true",
   qrCode:{},
   name:'',
   phone:'',
   address:'',
   street:'',
   city:'',
   state:'',
   country:'',
   qr:'',
   signres: null,

   number:"+91",
   otp:"",
   token:null,
   defaultnum:"+91",
   profile:'',
   signout:false,
   users:'',
   
  };
  export default initialState;