import { ListCoupons } from "../../../../Infrastructure/Admin/CouponList";

const actions = {
    setLoading:
      (isLoading) =>
      ({ setState }) => {
        setState({ isLoading });
      },
      setChoice:
      ( choice) =>
      ({ setState }) => {
        setState({  choice });
      },
      setresponse:
      (response) =>
      ({ setState }) => {
        setState({ response });
      },
   
      Postcouponlist:
      () =>
      async ({ dispatch, getState }) => {
        dispatch(actions.setLoading(true));
        const { choice } = getState();
        var bodyFormdata = new FormData();
        bodyFormdata.append("choice",choice);
        try {
         const res= await ListCoupons(bodyFormdata);
         dispatch(actions.setresponse(res.data.data));
          dispatch(actions.setLoading(false));
        } catch (err) {
        } finally {
          dispatch(actions.setLoading(false));
          
        }
      },
    }
    export default actions;