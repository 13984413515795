import React, { useEffect } from "react";
import { Ad } from "./style";
import { Carousel } from "antd";
import { GetAdList } from "./store";

function Banner() {
  const [{ banner }, { getbanner }] = GetAdList();
  useEffect(() => {
    getbanner();
  }, []);

  return (
    <Ad>
      <Carousel autoplay>
        {banner?.map((res) => (
          <div className="items">
            <img src={res.ad_image} alt=" " />
          </div>
        ))}
      </Carousel>
    </Ad>
  );
}

export default Banner;
