import {get,post} from '../common/remote/base_api'
export const GetPayment =(data) => {
    return get(`/userapp/make-payment/`,data);
  };
  export const PostSuccess = (data) => {
    return post(`/userapp/payment-success/`,data);
  };
  export const Postcodecoupon = (data) => {
    return post(`/userapp/apply-coupon-code/`,data);
  };
