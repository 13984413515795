import {
  GetProfile,
  Login,
  OTP,
  PostQrcode,
} from "../../../../Infrastructure/Website/Qrcode";
import { notification } from "antd";
import { firebase } from "../../../../common/firebase/index";
const actions = {
  setisLoading:
    (isLoading) =>
    ({ setState }) => {
      setState({ isLoading });
    },
  setQrcode:
    (qrCode) =>
    ({ setState }) => {
      setState({ qrCode });
    },
  setcode:
    (code) =>
    ({ setState }) => {
      setState({ code });
    },
  setName:
    (name) =>
    ({ setState }) => {
      setState({ name });
    },
  setPhone:
    (phone) =>
    ({ setState }) => {
      setState({ phone });
    },
  setaddress:
    (address) =>
    ({ setState }) => {
      setState({ address });
    },
  setStreet:
    (street) =>
    ({ setState }) => {
      setState({ street });
    },
  setCity:
    (city) =>
    ({ setState }) => {
      setState({ city });
    },
  setState:
    (state) =>
    ({ setState }) => {
      setState({ state });
    },
  setCountry:
    (country) =>
    ({ setState }) => {
      setState({ country });
    },
  setNumber:
    (number) =>
    ({ setState }) => {
      setState({ number });
    },
  setOtp:
    (otp) =>
    ({ setState }) => {
      setState({ otp });
    },
  setsignres:
    (signres) =>
    ({ setState }) => {
      setState({ signres });
    },
  settoken:
    (token) =>
    ({ setState }) => {
      setState({ token });
    },
  setdefaultnum:
    (defaultnum) =>
    ({ setState }) => {
      setState({ defaultnum });
    },
  setProfile:
    (profile) =>
    ({ setState }) => {
      setState({ profile });
    },
    setsignout:
    (signout) =>
    ({ setState }) => {
      setState({ signout });
    },
    setusers:
    (users) =>
    ({ setState }) => {
      setState({ users });
    },
  postqrcode:
    (data) =>
    async ({ dispatch, getState }) => {
      dispatch(actions.setisLoading(true));
      const { name, phone, address, street, city, state, country } = getState();
      var bodyFormdata = new FormData();
      bodyFormdata.append("name", name);
      bodyFormdata.append("phone", phone);
      bodyFormdata.append("address", address);
      bodyFormdata.append("street", street);
      bodyFormdata.append("city", city);
      bodyFormdata.append("state", state);
      bodyFormdata.append("country", country);
      try {
        const res = await PostQrcode(bodyFormdata);
        dispatch(actions.setQrcode(res.data));

        dispatch(actions.setisLoading(false));
      } catch (err) {
      } finally {
        dispatch(actions.setisLoading(false));
      }
    },
  getprofile:
    () =>
    async ({ setState, dispatch }) => {
      dispatch(actions.setisLoading(true));
      try {
        const res = await GetProfile();
        setState({ profile: res.data.data });
        dispatch(actions.setusers(res.data.data.chat_account_id));
     
      
      } catch (err) {
        dispatch(actions.setisLoading(true));
      } finally {
        dispatch(actions.setisLoading(false));
      }
    },
  onSubmit:
    () =>
    async ({ setState, dispatch, getState }) => {
      dispatch(actions.setisLoading(true));

      try {
        const { number } = getState();
        let temporary = "+91";
        let main = temporary + number;
        let int = parseInt(main);

        if (number.length < 10) {
          notification["warning"]({
            message: "warning",
            description: "Mobile number is not valid (+91 required)",
          });
          return false;
        } else {
          const signres = await Login(number);
          dispatch(actions.setsignres(signres));
        }
      } catch (err) {
      } finally {
        dispatch(actions.setisLoading(false));
      }
    },
  sentVarify:
    (navigate) =>
    async ({ setState, dispatch, getState }) => {
      dispatch(actions.setisLoading(true));
      try {
        const { otp, signres, profile } = getState();
        if (otp === "") {
          notification["warning"]({
            message: "warning",
            description: "OTP feild is empty ",
          });
          return false;
        } else {
          await OTP(otp, signres);
          const user = firebase.auth().currentUser;
          const mytoken = user && (await user.getIdToken());

          localStorage.setItem("token", mytoken);
        }
        notification["success"]({
          message: "success",
          description: "Login Successfully",
        });

        navigate("/premium");
      } catch (err) {
        notification["warning"]({
          message: "warning",
          description: "Invalid OTP Verification failed",
        });
      } finally {
        dispatch(actions.setisLoading(false));
        dispatch(actions.getprofile());
      }
    },
};
export default actions;
