import styled from "styled-components";
export const Loading=styled.div`
.body{
    display:flex;
    justify-content:center;
    align-items:center;
    width:100%;
    min-height:100vh;
    background:#74c7e7;
  }
  h2{
    color:#fff;
    font-size:3em;
    display:flex;
    @media(max-width:450px){
      font-size:2em;
    }
  }
  h2 span {
    animation:animate 4s linear infinite;
  }
  h2 span :nth-child(1){
    animation-delay:0s;
  }
  h2 span :nth-child(2){
    animation-delay:0.1s;
  }
  h2 span :nth-child(3){
    animation-delay:0.2s;
  }
  h2 span :nth-child(4){
    animation-delay:0.3s;
  }
  h2 span :nth-child(5){
    animation-delay:0.4s;
  }
  h2 span :nth-child(6){
    animation-delay:0.5s;
  }
  h2 span :nth-child(7){
    animation-delay:0.6s;
  }
  h2 span :nth-child(8){
    animation-delay:0.8s;
  }
  @keyframes animate{
    0%,100%
    {
      color:#fff;
      filter:blur(2px);
      text-shadow:0 0 10px #00b3ff;
                  0 0 20px #00b3ff;
                  0 0 30px #00b3ff;
                  0 0 40px #00b3ff;
                  0 0 80px #00b3ff;
                  0 0 120px #00b3ff;
                  0 0 200px #00b3ff;
                  0 0 300px #00b3ff;
                  0 0 400px #00b3ff;
    }
    25%,50%
    {
      color:#fff;
      filter:blur(0px);
      text-shadow:none;
    }
  }
`