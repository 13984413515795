import { AdminSiteStatus } from "../../../../Infrastructure/Admin/Header";

const actions = {
  setisLoading:
    (isLoading) =>
    ({ setState }) => {
      setState({ isLoading });
    },
  setTogglebar:
    (status) =>
    ({ setState }) => {
      setState({ status });
    },
    setMenubar:
    (menubar) =>
    ({ setState }) => {
      setState({ menubar });
    },
  postsitestatus:
    () =>
    async ({ dispatch, getState }) => {
      dispatch(actions.setisLoading(true));
      const { status } = getState();
      console.log(status,'...admin')
      const data={status}
   
      try {
        const res = await AdminSiteStatus(data);
        // dispatch(actions.setTogglebar(res.data.data.status));
        
        dispatch(actions.setisLoading(false));
      } catch (err) {
      } finally {
        dispatch(actions.setisLoading(false));
      }
    },
};

export default actions;
