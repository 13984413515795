import React from "react";
import { Footerpart } from "./style";
import { useNavigate } from "react-router";
function Footer() {
  let navigate = useNavigate();

  const handleClick = () => {
    navigate("/privacypolicy");
  };
  return (
    <Footerpart>
      <div className="footer">
        <div className="wrapper">
        <div className='intro'>
        <a href="#about" style={{ textDecoration: "none" }}>
          <h1>About</h1></a>
          <a href="#contact" style={{ textDecoration: "none" }}>
          <h1>Contact</h1></a>
        </div>
          <div className='policy'>
          <h1 onClick={handleClick}>Policies</h1>
       
          </div>
       
        </div>
      </div>
    </Footerpart>
  );
}

export default Footer;