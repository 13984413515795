import { GetPaymentList } from "../../../../Infrastructure/Admin/PaymentList";

const actions = {
    setLoading:
      (isLoading) =>
      ({ setState }) => {
        setState({ isLoading });
      },
      setPayment:
      (payment) =>
      ({ setState }) => {
        setState({ payment });
      },
      getpaymentlist:
      () =>
      async ({ setState, dispatch }) => {
        dispatch(actions.setLoading(true));
        try {
          const res = await GetPaymentList();
          setState({ payment: res.data.data });
        } catch (err) {
          dispatch(actions.setLoading(true));
        } finally {
          dispatch(actions.setLoading(false));
        }
      },
    }
    export default actions;