import React from "react";
import { Policy } from "./style";
function Privacy() {
  return (
    <Policy>
      <div className="pagesection" id="policy">
        <div className="pagewidth">
          <h1>Chupqr Terms & Privacy Policy</h1>

          <p>
            Chupqr is designed to never collect or store any sensitive
            information. Our Terms of Service and Privacy Policy are available
            below.
            <br />
            ● Terms of service <br />● Privacy Policy
          </p>
          <h1>Terms of Service</h1>
          <p>
            You agree to our Terms of Service (“Terms”) by installing or using
            our apps, services, or website (together, “Services”).
          </p>
          <h1>About our services</h1>
          <p>
            ● Minimum Age: You must be at least 16 years old to use our
            Services. The minimum age to use our Services without parental
            approval may be higher in your home country.
            <br />
            ● Account Registration: To create an account you must register for
            our Services using your phone number. You agree to receive text
            messages and phone calls (from us or our third-party providers) with
            verification codes to register for our Services.
            <br />
            ● Privacy of user data: Chupqr does not sell, rent or monetize your
            personal data or content in any way – ever.
            <br />
            <p>
              Please read our Privacy policy to understand how we safeguard the
              information you provide when using our Services. For the purpose
              of operating our Services, you agree to our data practices as
              described in our Privacy Policy, as well as the transfer of your
              encrypted information and metadata to service providers or
              partners. Examples would be Third Party Providers sending you a
              verification code.
            </p>
            <br />
            ● Software: In order to enable new features and enhanced
            functionality, you consent to downloading and installing updates to
            our Services.
            <br />
            ● Fees and Taxes. You are responsible for data and mobile carrier
            fees and taxes associated with the devices on which you use our
            Services.
            <br />
            ● Payment: Inorder to enable the premium features, user shall make
            the necessary payments using the various payment mechanism available
            on the Platform as per the Invoiced Amount raised by the Chupqr app.
            <br />
            <p>
              The User shall also be liable to pay any additional charges and/or
              applicable taxes that may be applicable to the transaction.
            </p>
            <br />
          </p>
          <h1>Using Chupqr</h1>

          <p>
            ● Our Terms and Policies: You must use our Services according to our
            Terms and posted policies. If we disable your account for a
            violation of our Terms, you will not create another account without
            our permission.
            <br />
            ● Legal and Acceptable Use: You agree to use our Services only for
            legal, authorized, and acceptable purposes. You will not use (or
            assist others in using) our Services in ways that: (a) violate or
            infringe the rights of Chupqr, our users, or others, including
            privacy, publicity, intellectual property, or other proprietary
            rights; (b) involve sending illegal or impermissible communications
            such as bulk messaging, auto-messaging, and auto-dialing.
            <br />
            ● Harm to Chupqr: You must not (or assist others to) access, use,
            modify, distribute, transfer, or exploit our Services in
            unauthorized manners, or in ways that harm chupqr, our Services, or
            systems. For example you must not (a) gain or try to gain
            unauthorized access to our Services or systems; (b) disrupt the
            integrity or performance of our Services; (c) create accounts for
            our Services through unauthorized or automated means; (d) collect
            information about our users in any unauthorized manner; or (e) sell,
            rent, or charge for our Services.
            <br />
            ● Keeping Your Account Secure: Chupqr embraces privacy by design and
            does not have the ability to access your messages. You are
            responsible for keeping your device and your Chupqr account safe and
            secure.
            <br />
            ● Third-party services: Our Services may allow you to access, use,
            or interact with third-party websites, apps, content, and other
            products and services. When you use third-party services, their
            terms and privacy policies govern your use of those services.
            <br />
            ● No Access to Emergency Services: Our Services do not provide
            access to emergency service providers like the police, fire
            department, hospitals, or other public safety organizations. Make
            sure you can contact emergency service providers through a mobile,
            fixed-line telephone, or other service.
            <br />
          </p>

          <h1>Your Rights and License with Chupqr</h1>

          <p>
            ● Your Rights: You own the information you submit through our
            Services. You must have the rights to the phone number you use to
            sign up for your Chupqr account.
            <br />
            ● Chupqr’s Rights: We own all copyrights, trademarks, domains,
            logos, trade dress, trade secrets, patents associated with our
            Services. You may not use our copyrights, trademarks, domains,
            logos, trade dress, patents unless you have our written permission.
            <br />
            ● Chupqr’s License to You: Chupqr grants you a limited, revocable,
            non-exclusive, and non-transferable license to use our Services in
            accordance with these Terms.
            <br />
          </p>
          <h1>Disclaimers and Limitations</h1>
          <p>
            Disclaimers: YOU USE OUR SERVICES AT YOUR OWN RISK AND SUBJECT TO
            THE FOLLOWING DISCLAIMERS. WE PROVIDE OUR SERVICES ON AN “AS IS”
            BASIS WITHOUT ANY EXPRESS OR IMPLIED WARRANTIES, INCLUDING, BUT NOT
            LIMITED TO, WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
            PURPOSE, TITLE, NON-INFRINGEMENT, AND FREEDOM FROM COMPUTER VIRUS OR
            OTHER HARMFUL CODE. CHUPQR DOES NOT WARRANT THAT ANY INFORMATION
            PROVIDED BY US IS ACCURATE, COMPLETE, OR USEFUL, THAT OUR SERVICES
            WILL BE OPERATIONAL, ERROR-FREE, SECURE, OR SAFE, OR THAT OUR
            SERVICES WILL FUNCTION WITHOUT DISRUPTIONS, DELAYS, OR
            IMPERFECTIONS. WE DO NOT CONTROL, AND ARE NOT RESPONSIBLE FOR,
            CONTROLLING HOW OR WHEN OUR USERS USE OUR SERVICES. WE ARE NOT
            RESPONSIBLE FOR THE ACTIONS OR INFORMATION (INCLUDING CONTENT) OF
            OUR USERS OR OTHER THIRD PARTIES. YOU RELEASE US, AFFILIATES,
            DIRECTORS, OFFICERS, EMPLOYEES, PARTNERS, AND AGENTS (TOGETHER,
            “CHUPQR PARTIES”) FROM ANY CLAIM, COMPLAINT, CAUSE OF ACTION,
            CONTROVERSY, OR DISPUTE (TOGETHER, “CLAIM”) AND DAMAGES, KNOWN AND
            UNKNOWN, RELATING TO, ARISING OUT OF, OR IN ANY WAY CONNECTED WITH
            ANY SUCH CLAIM YOU HAVE AGAINST ANY THIRD PARTIES.
            <br />
          </p>
          <p>
            Limitation of liability: THE CHUPQR PARTIES WILL NOT BE LIABLE TO
            YOU FOR ANY LOST PROFITS OR CONSEQUENTIAL, SPECIAL, PUNITIVE,
            INDIRECT, OR INCIDENTAL DAMAGES RELATING TO, ARISING OUT OF, OR IN
            ANY WAY IN CONNECTION WITH OUR TERMS, US, OR OUR SERVICES, EVEN IF
            THE CHUPQR PARTIES HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH
            DAMAGES. OUR AGGREGATE LIABILITY RELATING TO, ARISING OUT OF, OR IN
            ANY WAY IN CONNECTION WITH OUR TERMS, US, OR OUR SERVICES WILL NOT
            EXCEED ONE HUNDRED DOLLARS ($100). THE FOREGOING DISCLAIMER OF
            CERTAIN DAMAGES AND LIMITATION OF LIABILITY WILL APPLY TO THE
            MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW. THE LAWS OF SOME STATES
            OR JURISDICTIONS MAY NOT ALLOW THE EXCLUSION OR LIMITATION OF
            CERTAIN DAMAGES, SO SOME OR ALL OF THE EXCLUSIONS AND LIMITATIONS
            SET FORTH ABOVE MAY NOT APPLY TO YOU. NOTWITHSTANDING ANYTHING TO
            THE CONTRARY IN OUR TERMS, IN SUCH CASES, THE LIABILITY OF THE
            CHUPQR PARTIES WILL BE LIMITED TO THE FULLEST EXTENT PERMITTED BY
            APPLICABLE LAW.
            <br />
          </p>
          <p>
            Availability of Our Services: Our Services may be interrupted,
            including for maintenance, upgrades, or network or equipment
            failures. We may discontinue some or all of our Services, including
            certain features and the support for certain devices and platforms,
            at any time.
          </p>
          <h1>Resolving Disputes and Ending Terms</h1>
          <p>
            Resolving disputes: You agree to resolve any Claim you have with us
            relating to or arising out of our Terms, us, or our Services in the
            respective court of justice. You also agree to submit to the
            personal jurisdiction of such courts for the purpose of litigating
            all such disputes. The laws of India govern our Terms, as well as
            any disputes, whether in court or arbitration, which might arise
            between Chupqr and you, without regard to conflict of law
            provisions.
            <br />
            Ending these Terms: You may end these Terms with Chupqr at any time
            by deleting Chupqr from your device and discontinuing use of our
            Services. We may modify, suspend, or terminate your access to or use
            of our Services anytime for any reason, such as if you violate the
            letter or spirit of our Terms or create harm, risk, or possible
            legal exposure for Chupqr. The following provisions will survive
            termination of your relationship with Chupqr: “Licenses,”
            “Disclaimers,” “Limitation of Liability,” “Resolving dispute,”
            “Availability” and “Ending these Terms,” and “General”.
          </p>
          <h1>General</h1>
          <p>
            Chupqr may update the Terms from time to time. When we update our
            Terms, we will update the “Last Modified” date associated with the
            updated Terms. Your continued use of our Services confirms your
            acceptance of our updated Terms and supersedes any prior Terms. You
            will comply with all applicable export control and trade sanctions
            laws. Our Terms cover the entire agreement between you and Chupqr
            regarding our Services. If you do not agree with our Terms, you
            should stop using our Services.
            <br />
            If we fail to enforce any of our Terms, that does not mean we waive
            the right to enforce them. If any provision of the Terms is deemed
            unlawful, void, or unenforceable, that provision shall be deemed
            severable from our Terms and shall not affect the enforceability of
            the remaining provisions. Our Services are not intended for
            distribution to or use in any country where such distribution or use
            would violate local law or would subject us to any regulations in
            another country. We reserve the right to limit our Services in any
            country. If you have specific questions about these Terms,
            <br />
            please contact us at chupqr@gmail.com
          </p>

          <h1>Privacy Policy</h1>
          <p>
            Our “Privacy Policy” helps explain our data practices, including the
            information we process to provide our Services. It talks about what
            information we collect and how this affects you. It also explains
            the steps we take to protect your privacy, like building our
            Services so delivered messages aren’t stored by us and giving you
            control over who you communicate with on our Services. This Privacy
            Policy applies to all of our Services unless specified otherwise.
          </p>
          <br />
          <h1>Information We Collect</h1>
          <p>
            Chupqr must receive or collect some information to operate, provide,
            improve, understand, customize, support, and market our Services,
            including when you install, access, or use our Services.
          </p>
          <br />
          <p>
            The types of information we receive and collect depend on how you
            use our Services. We require certain information to deliver our
            Services and without this we will not be able to provide our
            Services to you. For example, you must provide your mobile phone
            number to create an account to use our Services.
          </p>
          <br />
          <p>
            Our Services have optional features which, if used by you, require
            us to collect additional information to provide such features. You
            will be notified of such collection, as appropriate. If you choose
            not to provide the information needed to use a feature, you will be
            unable to use the feature. For example, you cannot share your photos
            with other users, if you do not permit us to access your gallery or
            media from your device. Permissions can be managed through your
            Settings menu on both Android and iOS devices.
          </p>
          <br />
          <h1>Information you provide</h1>
          <p>
            ● Account Information: You must provide your mobile number to
            register which Chupqr App when you create a Chupqr account (Phone
            numbers are used to provide our Services to you and other Chupqr
            users). If you don’t provide us with this information, you will not
            be able to create an account to use our Services. You may optionally
            add other information to your account, such as a profile name and
            profile picture.
            <br />
            Additional technical information is stored on our servers, including
            randomly generated authentication tokens, keys, push tokens, and
            other material that is necessary to transmit messages. Chupqr limits
            this additional technical information to the minimum required to
            operate the Services.
            <br />
            ● Contacts: Chupqr is designed in such a way that it protects the
            privacy of your contacts. Information from your contacts are used
            only for the purpose of generating QR codes and that too as per the
            likeness of users. Any information from the users contact list is
            not saved in the database.
            <br />
            ● Data: Chupqr allows users to store documents such as Aadhar
            card, Driving license, PAN card, GSTIN and Account number in your
            phone memory and enables users to generate QR and are not saved in
            our database. Hence we have no access to any of these.
            <br />
            ● Camera and Microphone: Chupqr accesses and records camera sensor,
            which is used for the purpose of taking photos and scanning QR, to
            fulfil its purpose as a camera. Microphone permission is also used
            to provide users an option to send "audio message" by enabling the
            Microphone permission and recording audio. Chupqr requires
            permission (at least for Android 9 and earlier) to "access photos,
            media and files on your devices" (storage permission), as this
            permission is required for Android to save resultant files such as
            photos to your device.
            <br />
            ● Device info (only for IOS): Chupqr collects device info inorder to
            restore purchases i.e. when you uninstall your Chupqr A/c with
            premium features from your device and then install the same in
            another device, your IMEI number gets saved to our database inorder
            to restore your premium features.
            <br />
            ● Payment Information. A payment platform is available for Chupqr
            users. It is for the purpose of availing premium feature in Chupqr
            App or web. Chupqr does not process payments from users and instead
            relies on a different payment provider. It is the payment providers
            that handle and store your payment details.We do not have any access
            to this information. Although we work with payment providers, they
            are completely independent from Chupqr. Please study their relevant
            privacy policies before making your data available to them.
            <br />
            ● Additional Information. Additional technical information is stored
            on our servers, including randomly generated authentication tokens,
            keys, push tokens, and other material that is necessary to transmit
            messages. Chupqr limits this additional technical information to the
            minimum required to operate the Services.
            <br />
            ● Gallery: Chupqr allows users to share images from gallery via
            messages. This is enabled by seeking the users permission to access
            pictures from their device gallery. Photos from gallery are stored
            in our server in a highly secured and encrypted form, so that the
            user can share them through messages while chatting with other
            Chupqr users.
            <br />
          </p>
          <h1>Information we may share</h1>
          <p>
            ● Third Parties: We work with third parties to provide some of our
            Services. For example, our Third-Party Providers send a verification
            code to your phone number when you register for our Services. These
            providers are bound by their Privacy Policies to safeguard that
            information. When you use third-party services, their terms and
            privacy policies govern your use of those services.
            <br />
            <p>Other instances where Chupqr may need to share your data</p>
            <br />
            <p>
              ● To meet any applicable law, regulation, legal process or
              enforceable governmental request.
              <br />
              ● To enforce applicable Terms, including investigation of
              potential violations.
              <br />
              ● To detect, prevent, or otherwise address fraud, security, or
              technical issues. <br />● To protect against harm to the rights,
              property, or safety of Chupqr, our users, or the public as
              required or permitted by law.
            </p>
          </p>
          <h1>Keeping your personal data safe</h1>
          <p>
            ● Storing Data. The servers and networks that chupqr uses are
            completely owned by the Chupqr App. when you sign up for chupqr,
            your data is stored in our own servers and as such, we do not share
            your data with any third party services or other data centers. All
            data is stored in a highly secured system so that local chupqr
            engineers or physical intruders cannot get access. Retention. Unless
            stated otherwise in this Privacy Policy, the personal data that you
            provide us will only be stored for as long as it is necessary for us
            to fulfill our obligations in respect of the provision of the
            Services.
            <br />
          </p>
          <h1>Updates</h1>

          <p>
            We will update this privacy policy as needed so that it is current,
            accurate, and as clear as possible. Your continued use of our
            Services confirms your acceptance of our updated Privacy Policy.
            <br />
          </p>
          <h1>Terms</h1>
          <p>
            Please also read our Terms which also governs the terms of this
            Privacy Policy.
            <br />
          </p>
          <h1>Refund Policy</h1>
          <p>
            If you are facing any problems regarding the upgradation of your
            Chupqr account into a premium version even after making sucessful
            payment, We invite You to review our policy on refunds.
          </p>
          <h1>Interpretation and Definitions</h1>
          <p>
            {" "}
            ● Interpretation :The words of which the initial letter is
            capitalized have meanings defined under the following conditions.
            The following definitions shall have the same meaning regardless of
            whether they appear in singular or in plural.
          </p>
          <p>
            {" "}
            ● Definitions
            <p>
              For the purposes of this Refund Policy:
              <p>
                {" "}
                Application means the software program provided by the Company
                downloaded by You on any electronic device, named Chupqr
              </p>
              <p>Service refers to the Application.</p>
              <p>
                You means the individual accessing or using the Service, or the
                company, or other legal entity on behalf of which such
                individual is accessing or using the Service, as applicable
              </p>
            </p>
          </p>
          <h1>Condition for Refunds</h1>
          <p>
            Chupqr offers certain features or services for which a fee will be
            payable (“Paid Services”). Refund is applicable only when you face
            problems in accessing those paid services even after making
            sucessful payment.
          </p>
          <p>
            For any queries regarding Refunds, you can contact us
            at:chupqr@gmail.com
          </p>
          <h1>Contact Us</h1>
          <p> </p>
          <p>Don't hesitate to contact us if you have any questions.</p>
          <p> ● Via Email: chupqr@gmail.com</p>
        </div>
      </div>
    </Policy>
  );
}

export default Privacy;
