import { createStore, createHook, defaults } from "react-sweet-state";
import initialState from "./initialState";
import actions from "./actions";

defaults.devtools = true;
const Listcoupon = createStore({
  name: "list",
  initialState,
  actions,
});

export const GetCoupounlist = createHook(Listcoupon, {
  selector: (state) => state,
});