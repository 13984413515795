import styled from "styled-components";
export const Policy=styled.div`
.pagesection {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    text-align: center;

    .pagewidth {
      width: 70%;
      margin-top: 50px;
      margin-bottom: 50px;
      h1{
        font-family: "dm_sansregular";
        color: gray;
        display: flex;
        justify-content: start;
        font-size: 28px;
        font-weight:bold;
      }
      p{
        font-family: "dm_sansregular";
        text-align: justify;
        line-height: 30px;
        font-size: 18px;
      }
    }
`