import React, { useEffect } from "react";
import banner from "../../../image/chupqr.gif";
import { AppleOutlined, AndroidOutlined } from "@ant-design/icons";
import { Homepage } from "./style";
import AOS from "aos";

function Chupqrhome() {
  useEffect(() => {
    AOS.init({
      duration: 3000,
   
    });
  }, []);

  return (
    <Homepage id="about">
      <div className="home">
        <div className="container">
          <div className="row">
            <div className="qrright">
              <div className="frstimage">
                <img src={banner} alt="img" />
              </div>
            </div>
            <div className="qrleft">
              <div className="leftsection">
                <div className="lefthead">
                  <div data-aos="zoom-out-down">
                    <h1>Quick n Easy Contact Sharing</h1>
                  </div>
                  <div data-aos="zoom-out-down">
                    <p>
                      Ever felt awkward sharing your contact details? With
                      ChupQR, you don't need to! ChupQR is a time-saver app that
                      lets you share any contact details including personal and
                      official numbers, emails, and addresses anonymously by
                      generating a QR Code that can be scanned using any device.
                      No need to type out numbers or share your contact number!
                      You can now simply scan others' Contact QR Codes, their
                      location and even photos using ChupQR and save it on your
                      mobile. The app keeps your identity hidden as it does not
                      display your name, phone number or email when scanning
                      contact details. ChupQR, developed by Quadauq Technosys
                      Pvt Ltd, is a life-saver for individuals, it is an
                      image-saver for brands.
                    </p>
                  </div>

                  <div className="dwnldbtn">
                    <div className="btns" data-aos="zoom-out-down">
                      <a
                        href="https://play.google.com/store/apps/details?id=com.chupqr.chupqr"
                        target="blank"
                      >
                        <button>
                          <AndroidOutlined /> Play Store
                        </button>
                      </a>
                    </div>
                    <div className="btns" data-aos="zoom-out-down">
                      <button>
                        <AppleOutlined /> App Store
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Homepage>
  );
}

export default Chupqrhome;
