import React, { useEffect } from "react";
import { Appfeatures } from "./style";
import phone from "../../../image/app home page.png";
import AOS from "aos";

function Features() {
  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);

  return (
    <Appfeatures>
      <div className="space">
        <div className="container">
          <div className="row">
            <div className="frstpart">
              <div className="one" data-aos="fade-right">
                <h1>Generate & Scan QR Codes</h1>
                <p>
                  ChupQR is the easiest way to scan and generate QR codes for
                  contact sharing.
                </p>
              </div>
              <div className="one" data-aos="fade-right">
                <h1>Personal and Official data</h1>
                <p>
                  Create QR Codes for both personal and official contact
                  information.
                </p>
              </div>
              <div className="one" data-aos="fade-right">
                <h1>Save, Share and Make Calls</h1>
                <p>Save, Share and Use contact details using QR Codes.</p>
              </div>
            </div>
            <div className="middlepart">
              <div className="middleimage">
                <img src={phone} alt="" />
              </div>
            </div>
            <div className="lastpart">
              <div className="one" data-aos="fade-left">
                <h1>Contact Details</h1>
                <p>
                  Add and share your personal and official information such as
                  number, email and address.
                </p>
              </div>
              <div className="one" data-aos="fade-left">
                <h1>Download QR Code</h1>
                <p>Download the QR Code and use it anytime.</p>
              </div>
              <div className="one" data-aos="fade-left">
                <h1>Premium Subscription</h1>
                <p>
                  A one-time ₹111 subscription gives you lifetime access to
                  ChupQR to share more information such as Address, Email,
                  Official data etc. You can share the name and number for
                  free..
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Appfeatures>
  );
}

export default Features;
