import styled from "styled-components";
export const Login=styled.div`
   .loginpage{
       width:100%;
       height:100vh;
       display:flex;
       justify-content:center;
       align-items:center;
      
       .loginsection{
           width:300px;
           height:300px;
           box-shadow: -10px -10px 15px rgba(255,255,255,0.5), 10px 10px 15px rgba(70,70,70,0.12);
         border-radius:20px;
         background-color:#f5f5f5;
         .logininput{
             display:flex;
             flex-direction:column;
             gap:30px;
            
             justify-content:center;
             align-items:center;
             .inputsec{
                 display:flex;
                 flex-direction:column;
                 gap:30px;
                 width:80%;
                 margin-top:30px;
               
                 input{
                     width:100%;
                     height:100%;
                     border:none;
                     height:6vh;
                     box-shadow: -10px -10px 15px rgba(255,255,255,0.5), 10px 10px 15px rgba(70,70,70,0.12);
                     border-radius:6px;
                     padding:10px;
                     color:black;
                 }
                
                 }
                 .submitbtn{
                  
                     button{
                        width:120px;
                        height:40px;
                        border:2px solid white;
                        border-radius:6px;
                        box-shadow: -10px -10px 15px rgba(255,255,255,0.5), 10px 10px 15px rgba(70,70,70,0.12);
                     
                     }
             }
         }
       }
   }
`