import React ,{useEffect} from "react";
import { GetPayment } from "./store";
import { List } from "./style";
function Payment() {
  const[{payment},{getpaymentlist}]=GetPayment();
  useEffect(() => {
    getpaymentlist();
  }, [])
  console.log(payment,'...............payment')
  return (
    <List>
      <div className="content">
     
        <div className="wrapper">
          <div className="webheading">
            <h1>Payment List</h1>
          </div>
          <div className="boxx">
            <div className="add">
              {/* <button onClick={showModal}>Add</button> */}
            </div>
            <table>
              <thead>
                <tr className="row">
                  <th>Sl.No</th>
                  <th>Id</th>
                  <th>Cf order id</th>
                  <th>Amount</th>
                  <th>Cust Id</th>
                  <th>Payment Id</th>
                  <th>Received</th>
                  <th>Status</th>
                  <th>Currency</th>
                  <th>Reference Id</th>
                </tr>
              </thead>

              <tbody>
              {payment?.map((res, index) => (
                <tr className="rowbody">
                  <td>{index+1}</td>
                  <td>{res.id}</td>
                  <td>{res.cf_order_id}</td>
                  <td>{res.amount}</td>
                  <td>{res.customer_id}</td>
                  <td>{res.payment_id}</td>
                  <td>{res.payment_recieved===true ? "Yes" : "No"}</td>
                  <td>{res.payment_status}</td>
                  <td>{res.currency}</td>
                  <td>{res.referenceId}</td>
                </tr>
              ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </List>
  );
}

export default Payment;
