import React, { useEffect, useState } from "react";
import { App } from "./style";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Popconfirm, Modal } from "antd";
import { GetAppAd } from "./store";
import { firebase } from "../../../common/firebase";
function Ad() {
  const [
    { isLoading, ad },
    {
      getAd,
      deleteAd,
      PostCreateAd,
      setad_byte,
      PostUpdateAd,
      setImageupdates,
    },
  ] = GetAppAd();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModalAd = () => {
    setIsModalVisible(true);
  };

  const handleOkAd = () => {
    PostCreateAd(setIsModalVisible);
  };

  const handleCancelAd = () => {
    setIsModalVisible(false);
  };

  //....................update Ad
  const [isModalAd, setIsModalAd] = useState(false);
  const [iddd, setiddd] = useState("");
  const showModalAdupdate = (id) => {
    setiddd(id);
    setIsModalAd(true);
  };
  const handleOkAdupdate = () => {
    PostUpdateAd(iddd, setIsModalAd);
  };
  const handleCancelAdupdate = () => {
    setIsModalAd(false);
  };
  const [token, setToken] = useState("");
  useEffect(() => {
    return firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        user
          .getIdToken(true)
          .then((latestToken) => setToken(latestToken))
          .catch((err) => console.log(err));
      }
    });
  }, []);
  localStorage.setItem("token", token);

  useEffect(() => {
    getAd();
  }, [token]);
  console.log(ad, "......app");

  return (
    <App>
      <div className="content">
        {/* <div className="link">
          <p style={{ color: "grey" }}>Dashboard</p>
          <p>/</p>
          <p>Web Banner</p>
        </div> */}
        <div className="wrapper">
          <div className="webheading">
            <h1>App Ad</h1>
          </div>
          <div className="boxx">
            <div className="add">
              <button onClick={showModalAd}>Add</button>
            </div>
            <table>
              <thead>
                <tr className="row">
                  <th>Sl.No</th>
                  <th>File</th>
                  <th>Update</th>
                  <th>Delete</th>
                </tr>
              </thead>

              <tbody>
                {ad?.map((res, index) => (
                  <tr className="rowbody">
                    <td>{index + 1}</td>
                    <td>
                      <img src={res.ad_byte} alt="img" />
                    </td>

                    <td>
                      <button onClick={() => showModalAdupdate(res.id)}>
                        <EditOutlined style={{ fontSize: 18 }} />
                      </button>
                    </td>
                    <td>
                      <Popconfirm
                        placement={"rightBottom"}
                        title="Are you sure？"
                        onConfirm={() => deleteAd(res.id)}
                      >
                        <button>
                          <DeleteOutlined style={{ fontSize: 18 }} />
                        </button>
                      </Popconfirm>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Modal
        title="Add App Ad"
        visible={isModalVisible}
        onOk={handleOkAd}
        onCancel={handleCancelAd}
      >
        <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          <div style={{ width: "100px" }}>
            <select>
              <option value="none" selected disabled hidden>
                Select an Option
              </option>
              <option value="Image">Image</option>
              <option value="Video">Video</option>
            </select>
          </div>

          <input
            type="file"
            name="myfile"
            onChange={(e) => setad_byte(e.target.files[0])}
          />
        </div>
      </Modal>
      <Modal
        title="Update App Ad"
        visible={isModalAd}
        onOk={handleOkAdupdate}
        onCancel={handleCancelAdupdate}
      >
        <input
          type="file"
          name="myfile"
          onChange={(e) => setImageupdates(e.target.files[0])}
        />
      </Modal>
    </App>
  );
}

export default Ad;
