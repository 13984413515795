import { createStore, createHook, defaults } from "react-sweet-state";
import initialState from "./initialState";
import actions from "./actions";

defaults.devtools = true;
const Premiumaccount = createStore({
  name: "account",
  initialState,
  actions,
});

export const GetPremiumlist = createHook(Premiumaccount, {
  selector: (state) => state,
});