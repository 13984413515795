import React from "react";
import { Outlet } from "react-router-dom";
import styled from "styled-components";
import Header from "../Header";
import Sidebar from "../Sidebar";


function Page() {
  return (
    <div>
      <Header />
      <Container>
        <Sidebar />
        <Section>
          <Outlet />
        </Section>
      </Container>
    </div>
  );
}

export default Page;

export const Container = styled.div`
  display: flex;
`;
export const Section = styled.div`
  height: (100vh-80px);
  display: flex;
`;

