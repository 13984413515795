import styled from "styled-components";
export const Generator = styled.div`
  .qrcode {
    width: 100%;
    background-color: #f5f5f5;
    display: flex;
    justify-content: center;
    align-items: center;

    .wrapper {
      width: 80%;
      flex-wrap: wrap;
      display: flex;
      justify-content: center;
      flex-direction:column;
      align-items:center;
      .qhead{
           margin-top:15px;
           margin-bottom:15px;
           display:flex;
           justify-content:center;
          align-items:center;
          h1{
            font-size:26px;
            font-family: 'DM Sans', sans-serif;
            @media(max-width:740px){
              font-size:24px;
            }
            @media(max-width:400px){
              font-size:20px;
            }
          }

      }
      .input {
        display: flex;

        justify-content: center;
        align-items: center;
        width: 400px;
      //  height: 400px;
        flex-direction: column;
        box-shadow: -10px -10px 15px rgba(255, 255, 255, 0.5),
              10px 10px 15px rgba(70, 70, 70, 0.12);
            border-radius:20px;
        margin-bottom: 30px;
        // margin-top:20px;
        gap: 30px;
        .pwrqr{
          display:flex;
          width:90%;
          justify-content:flex-end;
          font-size:18px;
          margin-top:20px;
        }
        .section {
          display: flex;
          flex-direction: column;
          gap: 20px;
           margin-top:30px;
           margin-bottom:30px;

          input {
            width: 300px;
            height: 6vh;
            background: white;
            box-shadow: -10px -10px 15px rgba(255, 255, 255, 0.5),
              10px 10px 15px rgba(70, 70, 70, 0.12);
            border-radius: 10px;
            border: none;
            padding-left:10px;
            @media(max-width:760px){
              width:250px;
            }
            @media(max-width:450px){
              width:200px;
            }
          }
          .premium{
            display: flex;
            flex-direction: column;
            gap: 20px;

            input {
              width: 300px;
              height: 6vh;
              background: white;
              box-shadow: -10px -10px 15px rgba(255, 255, 255, 0.5),
                10px 10px 15px rgba(70, 70, 70, 0.12);
              border-radius: 10px;
              border: none;
              padding-left:10px;
              
              &:active{
                border-color: #3bbddd;
                border-style: solid;
                border-width: 1px;
               }
              @media(max-width:760px){
                width:250px;
              }
              @media(max-width:450px){
                width:200px;
              }
            }

          }
          .generatebutton {
            display:flex;
            justify-content:center;
            align-items:center;
            
            button {
              width: 130px;
              height: 40px;
              letter-spacing:2px;
              font-size: 17px;
              border-radius: 4px;
              border: 2px solid white;
              box-shadow: -10px -10px 15px rgba(255, 255, 255, 0.5),
                10px 10px 15px rgba(70, 70, 70, 0.12);
              border-radius: 20px;
              // background: #ececec;
              background: #3bbcdc;
              outline: none;
              color:white;
              // opacity:0.6;
              @media(max-width:400px){
                font-size:16px;
              }
            }
          }
          @media(max-width:740px){
            margin-top:30px;
          }
        }
        .dimension{
          padding-top: 20px;
          display: flex;
          justify-content: space-around;
          gap: 10px;
        }
        .imageqr {
          margin-bottom:30px;
          gap:20px;
          display:flex;
          flex-direction:column;
         
          .www{
          width: 150px;
          height: 150px;
          border: none !important;
          background: transparent !important
          margin-bottom:50px;
          display:flex;
          flex-direction:column;
           gap:10px;
       
          img {
            width: 100%;
            height: 100%;
            
          }
          h1{
            font-size:18px;
          }
        }
          .qrdwnld{
            display:flex;
            justify-content:center;
            align-items:center;
            button{
              width: 130px;
              height: 40px;
              letter-spacing:2px;
              font-size: 16px;
              border-radius: 4px;
              border: 2px solid white;
              box-shadow: -10px -10px 15px rgba(255, 255, 255, 0.5),
                10px 10px 15px rgba(70, 70, 70, 0.12);
              border-radius: 20px;
              background: #3bbcdc;
              outline: none;
              color: white;
            }
         
          }
       
        }
        @media(max-width:980px){
          width:350px;
          // height:350px;
        }
        @media(max-width:740px){
          width:300px;
          // height:300px;
        }
        @media(max-width:400px){
          width:250px;
          // height:250px;
        }
      }
    }
  }
`;

export const Login = styled.div`
// .signup-page:before {
//   content: "";
//   position: absolute;
//   // background: inherit;
//   z-index: -1;
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   box-shadow: inset 0 0 2000px;
//   margin: -20px;
// }

  
.signup-page {
  // width: 30rem;
  // height: 28rem;
  // box-shadow: 0 0 5px 0 white;
  border-radius: 20px;

  z-index: 1;
  
  overflow: hidden;
  margin-bottom: 100px;

    
  .signup {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 140px;

    h1 {
      font-family: "dm_sansregular";
      font-size: 40px;
      font-weight: bold;
      color: white;
  
    }

  }
  
  .input {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    align-items: center;
    border-radius: 8px;

    .box {
      display: flex;
      justify-content: center;
      width: 300px;
      align-items: center;
      label {
        font-family: "dm_sansregular";
        color: white;
        font-size: 18px;
        font-weight: bold;
      }
      input {
        width: 200px;
        height: 40px;
        border-radius: 20px;
        padding-left: 10px;
        border-color: #f5f5f5;
        outline: none;
        font-size: 18px;
        background-color:#f5f5f5;
       letter-spacing:3px;
       color:black;
       opacity:0.6;
     
      
      }
      input::placeholder {
        color: black;
      }
    
    }
    .box1 {
      display: flex;
      justify-content: center;
      width: 300px;
      align-items: center;
      label {
        font-family: "Poppins";
        font-size: 16px;
        font-weight: bold;
      }
      input {
        width:200px;
        height: 40px;
        border-radius: 20px;
        padding-left: 40px;
        border-color: white;
        font-size: 16px;
        outline: none;
        border: 1px solid #3bbddd;
        color:black;
        letter-spacing:3px;
       opacity:0.6;
   
      }
      input::placeholder {
        color: grey;
        font-size:15px;
        letter-spacing:none;
        padding-left:none;
      }
    }
  }
  .button {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    button {
      background:  #3bbcdc;
      padding: 5px;
      width: 200px;
      border: none;
      height: 40px;
      border-radius: 20px;
      font-size: 16px;
      font-family: 'Montserrat', sans-serif;
      font-weight: bold;
      color: white;
      text-align: center;
      cursor: pointer;
      &:active {
        background-color: black;
        color: white;
      }
    }

`;
