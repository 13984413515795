import styled from "styled-components";
export const Appfeatures = styled.div`
  .space {
    padding-top: 100px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;

    .container {
      width: 80%;
      padding:10px;
      .row {
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        .frstpart {
          flex: 0 0 33.333333%;
          max-width: 33.333333%;
          position: relative;
          width: 100%;
          display: flex;
          justify-content: center;
          gap: 30px;
          flex-direction: column;
        
          .one {
            display: flex;
            flex-direction: column;
             justify-content:center;
             align-items:center;
          
            h1 {
              font-size: 20px;
              font-weight: bold;
              font-family: 'DM Sans', sans-serif;
              @media(max-width:500px){
            
                font-size:18px;
               }
            }
            p {
              font-size: 18px;
              text-align: justify;
              word-wrap: normal;
              letter-spacing:-1px;
              word-spacing:normal;
              font-family: 'DM Sans', sans-serif;
              // width: 365px;
              // @media(max-width:1335px){
              //   width:310px;
              // }
              // @media(max-width:1235px){
              //   width:260px;
              // }
           @media(max-width:400px){
             font-size:16px;
           }
           
            }
         
          }

        }
        .middlepart {
          flex: 0 0 33.333333%;
          max-width: 33.333333%;
          position: relative;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          .middleimage {
            width: 300px;
            height: 600px;
            img {
              width: 100%;
              height: 100%;
            }
            // .screen {
            //   position: absolute;
             
            //   left: 15%;
            //   top: 12.8%;
            //   right: 14.5%;
            //   bottom: 12.3%;
            //   background:black;
            //   border-radius:4px;
            //   background-color:black;
            // }
            @media(max-width:1200px){
              width:250px;
              height:500px;
            }
            @media(max-width:760px){
              width: 215px;
              height: 400px;
             
            }
            @media(max-width:539px){
              height: 356px;
            }
          }
          @media(max-width:980px){
            max-width:unset;
            flex:unset;
            padding-top:30px;
          }
        }
        .lastpart {
          flex: 0 0 33.333333%;
          max-width: 33.333333%;
          position: relative;
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 30px;
          justify-content: center;
          .one {
            display: flex;
            flex-direction: column;
            justify-content:center;
            align-items:center;
         
            h1 {
              font-size:20px;
              font-weight: bold;
              font-family: 'DM Sans', sans-serif;
              @media(max-width:500px){
               
                font-size:18px;
              }
            }
            p {
              font-size: 18px;
              text-align:justify;
              letter-spacing:-1px;
              word-spacing:normal;
              font-family: 'DM Sans', sans-serif;
              // width: 365px;
              // @media(max-width:1335px){
              //   width:310px;
              // }
              // @media(max-width:1235px){
              //   width:260px;
              // }
              @media(max-width:400px){
                font-size:16px;
              }
           
            }
            @media(max-width:980px){
              padding-top:30px;
            }
          }
       
        }
        @media(max-width:980px){
          flex-direction:column;
          justify-content: center;
          align-items:center;
        }
      }
      @media(max-width:980px){
        width:100%;
      }
    }
  }
`;
