import { AdminStatusImages } from "../../../../Infrastructure/Admin/Sitestatusimage";

const actions = {
    setLoading:
      (isLoading) =>
      ({ setState }) => {
        setState({ isLoading });
      },
      setImages:
      (images) =>
      ({ setState }) => {
        setState({images });
      },
      PostStatusimage:
      (setIsModalVisible) =>
      async ({ dispatch, getState }) => {
        dispatch(actions.setLoading(true));
        const { images } = getState();
        var bodyFormdata = new FormData();
        bodyFormdata.append("ad_image",images);
        try {
         const res= await AdminStatusImages (bodyFormdata);
          dispatch(actions.setImages(res.data.image));
          dispatch(actions.setLoading(false));
        } catch (err) {
        } finally {
          dispatch(actions.setLoading(false));
          setIsModalVisible(false);
        }
      },
    }
   export default actions;