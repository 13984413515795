import { GetBanner } from "../../../../Infrastructure/Website/Banner";

const actions = {
    setLoading:
      (isLoading) =>
      ({ setState }) => {
        setState({ isLoading });
      },
      setBanner:
      (banner) =>
      ({ setState }) => {
        setState({ banner });
      },
      getbanner:
      () =>
      async ({ setState,dispatch }) => {
        dispatch(actions.setLoading(true));
        try {
          const res = await GetBanner();
          setState({banner :res.data.data})
        
      
        } catch (err) {
          dispatch(actions.setLoading(true));
        } finally {
          dispatch(actions.setLoading(false));
        }
      },
    }
    export default actions;