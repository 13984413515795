import { createStore, createHook, defaults } from "react-sweet-state";
import initialState from "./initialState";
import actions from "./actions";

defaults.devtools = true;
const Payment = createStore({
  name: "payment",
  initialState,
  actions,
});

export const GetPayment = createHook(Payment, {
  selector: (state) => state,
});