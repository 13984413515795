import styled from "styled-components";
export const Homepage = styled.div`

  .home {
    // margin-top: 80px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f5f5f5;

    .container {
      // width: 90%;
      // display: flex;
      // judtify-content: center;
      scroll-snap-type:y mandatory;
      overflow-y:scroll;
      .row {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content:center;
        align-items:center;
    
        .qrright {
          width: 50%;
          display: flex;
          justify-content: center;

          .frstimage {
            position: relative;
            width: 60%;
            height: 90vh;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
            @media(max-width:2800px){
              width:55%;
              height:75%;
            }
         @media(max-width:1180px){
          width: 80%;
          height: 80vh;
         }
         @media(max-width:980px){
          width: 70%;
          // height: 60vh;
          height:unset;
         }
         @media(max-width:840px){
          width: 60%;
         height:60vh;
        
         }
         
         @media(max-width:660px){
           height:50vh;
           width:50%;
         }
          @media(max-width:565px){
            height:40vh;
            width:40%;
          }
          @media(max-width:391px){
            width:50%;
          }
          }
          @media(max-width:1180px){
            width:40%;
          }
         @media(max-width:840px){
           width:60%;
         }
         @media(max-width:660px){
           width:70%;
         }
         @media(max-width:565px){
           width:80%;
         }
         @media(max-width:495px){
           width:100%;
         }
        }
        .qrleft {
          width: 50%;
          display: flex;
          // padding-top: 100px;
          .leftsection {
            width: 80%;
            margin-top:30px;
            .lefthead {
            
              // flex-direction:column;
              h1 {
                font-size: 26px;
                font-family: 'DM Sans', sans-serif;
                display:flex;
              justify-content:center;
              align-items:center;
                opacity:0.8;
                @media(max-width:950px){
                  font-size:24px;
                }
                @media(max-width:584px){
                  font-size:20px;
                }
                @media(max-width:468px){
                  font-size:18px;
                }
                @media(max-width:420px){
                  text-align:center;
                }
               
              }
              p {
                text-align: justify;
                font-size: 18px;
                padding: 10px;
                letter-spacing:-1px;
                word-spacing:normal;
                opacity:0.8;
                font-family: 'DM Sans', sans-serif;
                @media(max-width:468px){
                  font-size:16px;
                }
             
              }
              .dwnldbtn {
                display: flex;
                justify-content: center;
                gap: 20px;
                margin-bottom:30px;
                .btns {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  padding-top: 20px;
                a{
                  text-decoration:none;
                  color:black;
                }
                  button {
                    width: 200px;
                    height: 60px;

                    font-size: 18px;
                    border-radius: 4px;
                    border: 2px solid white;
                    box-shadow: -10px -10px 15px rgba(255, 255, 255, 0.5),
                      10px 10px 15px rgba(70, 70, 70, 0.12);
                    border-radius: 100px;
                    background: #ececec;
                    outline: none;
                    color: black;
                    opacity:0.8;
                    // margin-bottom: 30px;
                    &:hover {
                      color: red;
                    }
                    @media(max-width:980px){
                      width:170px;
                      height:50px;
                    }
                    @media(max-width:650px){
                      width: 147px;
                      height: 40px;
                      font-size:16px
                    }
                  }
                  @media(max-width:550px){
                   padding-top:unset;
                 
                  }
                }
                @media(max-width:550px){
                  flex-direction:column;
               
                }
              }
            }
          
          }
        @media(max-width:1180px){
          margin-top:50px;
        }
        @media(max-width:840px){
          width:80%;
          justify-content:center;
          align-items:center;
        }
         @media(max-width:660px){
           width:70%;
         } 
         
        }
      @media(max-width:980px){
        margin-top:30px;
      }
      }
      // @media(max-width:980px){
      //   width:100%;
      // }
    }
  }
`;
