import { createStore, createHook, defaults } from "react-sweet-state";
import initialState from "./initialState";
import actions from "./actions";

defaults.devtools = true;
const Generatecode = createStore({
  name: "generate",
  initialState,
  actions,
});

export const CouponcodeGenerate = createHook(Generatecode, {
  selector: (state) => state,
});