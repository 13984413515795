import React, { useEffect } from "react";
import { GetPremiumlist } from "./store";
import { PremiumCount } from "./style";
function PremiumList() {
  const [{ isLoading, premiumAccount }, { getpremiumlist }] = GetPremiumlist();
  useEffect(() => {
    getpremiumlist();
  }, []);
  console.log(premiumAccount, "....................accnt");
  return (
    <PremiumCount>
      <div className="content">
        <div className="wrapper">
          <div className="webheading">
            <h1>Premium List</h1>
          </div>
          <div className="boxx">
            <div className="add">
              {/* <button onClick={showModal}>Add</button> */}
            </div>
            <table>
              <thead>
                <tr className="row">
                  <th>Sl.No</th>
                  <th>Name</th>
                  <th>Premium Account</th>
                  <th>Coupon Used</th>
                </tr>
              </thead>

              <tbody>
                {premiumAccount?.map((res, index) => (
                  <tr className="rowbody">
                    <td>{index + 1}</td>
                    <td>{res.name}</td>
                    <td>{res.premium_account === true ? "Yes" : "No"}</td>
                    <td>{res.coupon_used === true ? "Yes" : "No"}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </PremiumCount>
  );
}

export default PremiumList;
