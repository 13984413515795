import React, { useEffect, useState } from "react";
import { GetCoupounCode } from "./store";
import { Generate, Logincode } from "./style";
import { firebase } from "../../../common/firebase";
import { Modal } from "antd";
import { useNavigate } from "react-router-dom";
function CoupounCode() {
  let navigate = useNavigate();
  const [
    { isLoading, coupoun, signres, phone_number, otp },
    {
      getcoupounlist,
     
   
      sentVarifycode,
      onSubmitcode,
      setphoneNumber,
      setotp,
    },
  ] = GetCoupounCode();
  console.log(signres, "................response");
  useEffect(() => {
    getcoupounlist();
  }, []);

 
 
  const [isModalVisiblecode, setIsModalVisiblecode] = useState(false);
  const showModalcode = () => {
    setIsModalVisiblecode(true);
  };
  const handleOkcode = () => {
    setIsModalVisiblecode(false);
  };

  const handleCancelcode = () => {
    setIsModalVisiblecode(false);
  };
  const [token, setToken] = useState("");
  useEffect(() => {
    //import { firebase } from "../../../Common/firebase";
    return firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        user
          .getIdToken(true)
          .then((latestToken) => setToken(latestToken))
          .catch((err) => console.log(err));
      }
    });
  }, []);
  return (
    <Generate>
      <div className="content">
        <div className="wrapper">
          <div className="webheading">
            <h1>Coupoun Premium Account</h1>
          </div>
          <div className="boxx">
            <div className="add">
              <button onClick={showModalcode}>Generate</button>
            </div>
            <table>
              <thead>
                <tr className="row">
                  <th>Sl.No</th>
                  <th>Id</th>
                  <th>Name</th>
                  <th>Phone Number</th>
                 
                   <th>coupon used</th>
                  <th>Premium Account</th>
                </tr>
              </thead>

              <tbody>
                {coupoun?.map((res, index) => (
                  <tr className="rowbody">
                    <td>{index + 1}</td>
                    <td>{res.id}</td>
                    <td>{res.name}</td>
                    <td>{res.phone_number}</td>
                    <td>{res.coupon_used===true ? "Yes" : "No"}</td>
                    <td>{res.premium_account===true ? "Yes" : "No"}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Modal
        visible={isModalVisiblecode}
        onOk={handleOkcode}
        onCancel={handleCancelcode}
        footer={null}
      >
        <Logincode>
          {signres === null ? (
            <div className="signup-page">
              <div className="input">
                <div className="box">
                  <input
                    maxlength="13"
                    type="text"
                    placeholder="Enter your Number"
                    value={phone_number}
                    onChange={(e) => setphoneNumber(e.target.value)}
                  ></input>
                </div>
              </div>
              <div id="recaptcha-container"></div>
              <div className="button">
                <button onClick={() => onSubmitcode()}>Send OTP</button>
              </div>
            </div>
          ) : (
            <div className="signup-page">
              <div className="input">
                <div className="box1">
                  <input
                    type="text"
                    placeholder="Enter your OTP"
                    onChange={(e) => setotp(e.target.value)}
                    value={otp}
                  ></input>
                </div>
              </div>

              <div className="button">
                <button
                  type="button"
                  onClick={() => sentVarifycode(navigate,setIsModalVisiblecode(false))}
                >
                  Sign in
                </button>
              </div>
            </div>
          )}
        </Logincode>
      </Modal>
    </Generate>
  );
}

export default CoupounCode;
