import styled from "styled-components";
export const Footerpart=styled.div`
  .footer{
      width:100%;
      height:80px;
      display:flex;
      justify-content:center;
      align-items:center;
      background: #3bbcdc;
      .wrapper{
          width:90%;
          display:flex;
          justify-content:space-between;
          align-items:center;
          gap:20px;
          .policy{
            display:flex;
            gap:20px;
            @media(max-width:600px){
              gap:10px;
            }
            h1{
              font-size:18px;
              color:white;
              cursor:pointer;
              @media (max-width:600px){
                font-size:16px;
              }
              @media(max-width:427px){
                font-size:14px;
              }
           }
          }
        .intro{
          display:flex;
          gap:20px;
          @media(max-width:600px){
            gap:10px;
          }
          h1{
            font-size:18px;
            color:white;
            cursor:pointer;
            @media (max-width:600px){
              font-size:16px;
            }
            @media(max-width:427px){
              font-size:14px;
            }
         }
        }
        @media (max-width:600px){
          width:90%;
        }
        @media(max-width:370px){
          width:95%;
        }
        @media(max-width:350px){
          flex-direction: column;
          gap:unset;
        }
      }
  }
`