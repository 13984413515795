import styled from "styled-components";
export const Adminheader = styled.div`
  display: flex;
  .chupqr {
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // background:rgba(0,0,0,.1);
    background: #f5f5f5;
    position: fixed;
    z-index: 100;
    padding: 50px;
    gap: 10px;
    .qrlogo {
      width: 80%;
      display: flex;
      gap: 20px;
      justify-content: center;
      align-items: center;
      width: 180px;
      height: 60px;
      // background-color:red;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .menu {
        display: none;
        @media (max-width: 1020px) {
          display: block;
        }
      }
    }
    .qrhead {
      h1 {
        font-size: 30px;
        color: #30a5c1;
        font-weight: bold;
        margin: 0px;
        padding: 0px;
        font-family: "Poppins", sans-serif;
      }
    }
    .power {
      display: flex;
      gap: 20px;
      justify-content: center;
      align-items: center;
    }
  }
`;

export const DIV = styled.div`
  .drawer {
    // width: 15vw;
    // height: 90vh;

    // background:#e5e5e5;;

    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    .drawersec {
      height:550px;
      overflow-y: scroll;
      .drawerhead {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        h1 {
          font-size: 20px;
          color: black;
          font-family: "montserratregular";
        }
      }
      .drawerlistitems {
        // height: 630px;
        // overflow-y: scroll;

        ul {
          list-style: none;
          margin: 0px;
          padding: 0px;
          display: flex;
          gap: 30px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding-top: 10px;
          padding-bottom: 20px;
          li {
            font-size: 16px;
            width: 180px;
            height: 50px;
            padding: 14px;
            background-color: white;
            box-shadow: -10px -10px 15px rgba(255, 255, 255, 0.5),
              10px 10px 15px rgba(70, 70, 70, 0.12);
            border-radius: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: start;
            color: black;
            cursor: pointer;

            span {
            }
            &:hover {
              background-color: #e5e5e5;
            }
          }
        }
      }
    }
  }
`;
