import { GetPremiumAccount } from "../../../../Infrastructure/Admin/PremiumList";

const actions = {
    setLoading:
      (isLoading) =>
      ({ setState }) => {
        setState({ isLoading });
      },
      setpremiumAccount:
      (premiumAccount) =>
      ({ setState }) => {
        setState({ premiumAccount });
      },
      getpremiumlist:
      () =>
      async ({ setState, dispatch }) => {
        dispatch(actions.setLoading(true));
        try {
          const res = await GetPremiumAccount();
          setState({ premiumAccount: res.data.data });
        } catch (err) {
          dispatch(actions.setLoading(true));
        } finally {
          dispatch(actions.setLoading(false));
        }
      },
    }
    export default actions;