import { CreateAd, delAd, GetAd, UpdateAd } from "../../../../Infrastructure/Admin/Ad";
import { notification } from "antd";
const actions = {
  setLoading:
    (isLoading) =>
    ({ setState }) => {
      setState({ isLoading });
    },
  setAd:
    (ad) =>
    ({ setState }) => {
      setState({ ad });
    },
    setad_byte:
    (ad_byte)=>
    ({setState})=>{
        setState({ad_byte});
    },
    setImageupdates:
    (ad_imagess) =>
    ({ setState }) => {
      setState({ ad_imagess });
    },
    setfile_type:
    (file_type) =>
    ({ setState }) => {
      setState({ file_type });
    },
    
  getAd:
    () =>
    async ({ setState, dispatch }) => {
      dispatch(actions.setLoading(true));
      try {
        const res = await GetAd();
        setState({ ad: res.data.data });
      } catch (err) {
        dispatch(actions.setLoading(true));
      } finally {
        dispatch(actions.setLoading(false));
      }
    },
    deleteAd:
    (id) =>
    async ({ dispatch, getState }) => {
      try {
        dispatch(actions.setLoading(true));
        await delAd(id);
        notification["success"]({
          message: "successs",
          description: "Ad deleted Succesfully",
        });
        // dispatch(actions.getbanner());
      } catch (err) {
      } finally {
        dispatch(actions.setLoading(false));
      }
    },
    PostCreateAd:
    (setIsModalVisible) =>
    async ({ dispatch, getState }) => {
      dispatch(actions.setLoading(true));
      const { ad_byte,file_type } = getState();
      const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
          const fileReader = new FileReader();
          fileReader.readAsDataURL(file);
    
          fileReader.onload = () => {
            resolve(fileReader.result);
          };
    
          fileReader.onerror = (error) => {
            reject(error);
          };
        });
      };
     
      const base64 = await convertBase64(ad_byte);
      var bodyFormdata = new FormData();
      bodyFormdata.append("ad_byte", base64);
      bodyFormdata.append("file_type", file_type);
      console.log(bodyFormdata,'..................body')
      
      try {
        await CreateAd(bodyFormdata);

        dispatch(actions.setLoading(false));
      } catch (err) {
      } finally {
        dispatch(actions.setLoading(false));
        setIsModalVisible(false);
      }
    },
    PostUpdateAd:
    (id,setIsModalAd) =>
    async ({ dispatch, getState }) => {
      dispatch(actions.setLoading(true));
      const { ad_imagess } = getState();
      var bodyFormdata = new FormData();
      bodyFormdata.append("ad_image", ad_imagess);
      try {
        await UpdateAd(id,bodyFormdata);

        dispatch(actions.setLoading(false));
      } catch (err) {
      } finally {
        dispatch(actions.setLoading(false));
        setIsModalAd(false);
      }
    },
};

export default actions;
