import React from "react";
import { Myadminlogins } from "./store";
import { Login } from "./style";
import { useNavigate } from "react-router-dom";
function Loginpage() {
  const [
    { isLoading, Username, password, token },
    { setisLoading, setUsername, setPassword, Submitlogins, setToken },
  ] = Myadminlogins();
  const navigate = useNavigate();
 
  function handleKeyPress(e) {
    if (e.key === "Enter") {
      if (Username !== "" && password!== "") {
        Submitlogins(navigate);
      }
    }
  }

  const handleChange = (e) => {
 
    e.preventDefault();
  
  };
  return (
    <Login>
      <div className="loginpage">
        <div className="loginsection">
          <div className="logininput">
            <div className="inputsec">
              <input
                type="text"
                placeholder="Username"
                value={Username}
                onKeyPress={(e) => handleKeyPress(e)}
                onCut={handleChange}
                onCopy={handleChange}
                onChange={(event) => {
                  setUsername(event.target.value);
                }}
              />
              <input
                type="password"
                placeholder="Password"
                value={password}
                onKeyPress={(e) => handleKeyPress(e)}
                onCut={handleChange}
                onCopy={handleChange}
                onChange={(event) => {
                  setPassword(event.target.value);
                }}
              />
            </div>
            <div className="submitbtn">
              <button type="submit" onClick={() => Submitlogins(navigate)}>
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </Login>
  );
}

export default Loginpage;
