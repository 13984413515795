import React, { useEffect, useState } from "react";
import { BannerWeb } from "./style";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { GetWebBanner } from "./store";
import { Popconfirm, Modal } from "antd";
import { useParams } from "react-router-dom";
import { firebase } from "../../../common/firebase/index";
function WebBanner({ match }) {
  const params = useParams();
  const [
    { isLoading, banner },
    {
      getbanner,
      deletebanner,
      PostCreatebanner,
      setImage,
      PostUpdatebanner,
      setImageupdate,
    },
  ] = GetWebBanner();
  console.log(banner, "..............banner");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    PostCreatebanner(setIsModalVisible);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  //update banner...................................
  const [isModalopn, setIsModalopn] = useState(false);
  const [idd, setidd] = useState("");
  const showModalupdate  = (id) => {
    setidd(id);
    setIsModalopn(true);
  };

  const handleOkupdate = () => {
    PostUpdatebanner(idd, setIsModalopn);
  };

  const handleCancelupdate = () => {
    setIsModalopn(false);
  };
  const [token, setToken] = useState("");
 
  

  useEffect(() => {
   
    return firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        user
          .getIdToken(true)
          .then((latestToken) => setToken(latestToken))
          .catch((err) => console.log(err));
      }
    });
  }, []);
  localStorage.setItem("token", token);

  useEffect(() => {
    getbanner();
  }, [token]);

  return (
    <BannerWeb>
      <div className="content">
        {/* <div className="link">
          <p style={{ color: "grey" }}>Dashboard</p>
          <p>/</p>
          <p>Web Banner</p>
        </div> */}
        <div className="wrapper">
          <div className="webheading">
            <h1>Web Banner</h1>
          </div>
          <div className="boxx">
            <div className="add">
              <button onClick={showModal}>Add</button>
            </div>
            <table>
              <thead>
                <tr className="row">
                  <th>Sl.No</th>
                  <th>File</th>
                  <th>Update</th>
                  <th>Delete</th>
                </tr>
              </thead>

              <tbody>
                {banner?.map((res, index) => (
                  <tr className="rowbody">
                    <td>{index + 1}</td>
                    <td>
                      <img src={res.ad_image} alt="img" />
                    </td>

                    <td>
                      <button onClick={() => showModalupdate(res.id)}>
                   
                        <EditOutlined style={{ fontSize: 18 }} />
                      </button>
                    </td>
                    <td>
                      <Popconfirm
                        placement={"leftBottom"}
                        title="Are you sure？"
                        onConfirm={() => deletebanner(res.id)}
                      >
                        <button>
                          <DeleteOutlined style={{ fontSize: 18 }} />
                        </button>
                      </Popconfirm>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Modal
        title="Add Banner"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <input
          type="file"
          name="myfile"
          onChange={(e) => setImage(e.target.files[0])}
        />
      </Modal>
      <Modal
        title="Update Banner"
        visible={isModalopn}
        onOk={handleOkupdate}
        onCancel={handleCancelupdate}
      >
        <input
          type="file"
          name="myfile"
          onChange={(e) => setImageupdate(e.target.files[0])}
        />
      </Modal>
    </BannerWeb>
  );
}

export default WebBanner;
