import styled from "styled-components";
export const Code=styled.div`
margin-top: 120px;
margin-left: 15vw;
width: 84vw;
display:flex;
justify-content:center;
align-items:center;
.borderdiv{
    width:400px;
    height:400px;
    display:flex;
    flex-direction:column;
    gap:10px;
    justify-content:center;
    align-items:center;
    box-shadow: -10px -10px 15px rgba(255, 255, 255, 0.5),
    10px 10px 15px rgba(70, 70, 70, 0.12);
  border-radius:20px;
  .generatebutton{
    display:flex;
    justify-content:center;
    align-items:center;
    button{
      width: 130px;
      height: 40px;
      letter-spacing:2px;
      font-size: 16px;
      border-radius: 4px;
      border: 2px solid white;
      box-shadow: -10px -10px 15px rgba(255, 255, 255, 0.5),
        10px 10px 15px rgba(70, 70, 70, 0.12);
      border-radius: 20px;
      background: #3bbcdc;
      outline: none;
      color: white;
    }
 
  }
  .codebox{
      width:150px;
      height:40px;
      border:1px solid black;
  }
}

`