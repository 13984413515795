import styled from "styled-components";
export const Ad = styled.div`
// margin-top:80px;

.items{
    width:1500px;
    height:800px;
  background-size: cover;
    img{
        width:100%;
        height:100%;
        object-fit:1500px 800px;
    }
    @media(max-width:980px){
        width:1300px;
        height:600px;
        // object-fit:1300px 600px;
    }
    @media(max-width:800px){
        width:1200px;
        height:500px;
        
    }

    @media(max-width:650px){
        width:1100px;
        height:400px;
        // object-fit:1100px 400px;
    }
    @media(max-width:550px){
      
        height:360px;
        
    }
    @media(max-width:450px){
      
        height:300px;
        
    }
    @media(max-width:350px){
        height:270px;
    }
}

}
`;
