const initialState = {
    isLoading: "true",
    coupoun:[],
    choice:'',

  number:"+91",
   otp:"",
   token:null,
   defaultnum:"+91",
   signout:false,
   signres:null,
  };
  export default initialState;