import React from 'react'
import { Heading } from './style'
import chupqr from '../../../image/chupqr.svg'
function Header() {
  return (
   <Heading>
       <div className='chupqr'>
         <div className='qrlogo'>
             <img src={chupqr} alt='logo'/>
        
         </div>
       
       </div>
   </Heading>
  )
}

export default Header